export const UrlConfig = {
  dashboard: {
    title: "Dashboard",
    url: "/dashboard",
  },
  leads: {
    title: "Leads",
    leads: {
      url: "/leads",
      title: "Leads",
      subsTitle: "Controle todos seus leads",
    },
  },

  campaign: {
    title: "Campanha",

    groupLeads: {
      url: "/campaign/groupLeads",
      title: "Grupos de Leads",
    },

    campaign: {
      url: "/campaign",
      title: "Campanhas",
    },
  },
  plans: {
    title: "Pagamentos",
    url: "/payments",
    subsTitle: "Aqui você pode configurar seus pagamentos",
  },
  profile: {
    title: "Perfil",
    url: "/profile",
  },

  help: {
    title: "Duvidas/suporte",
    url: "/help",
  },

  config: {
    title: "Configurações",
    url: "/config/details",
  },

  relatoriosProducao: {
    title: "Relatórios",

    producaoContract: {
      url: "/report/productionContract",
      title: "Produção contrato",
    },

    confirmationProducer: {
      url: "/report/confirmationProducer",
      title: "Confirmação parcelas",
    },
  },

  comissao: {
    title: "Comissões",

    corretora: {
      url: "/Comissoes/grades",
      title: "Grade de Comissões",
    },
    cadastros: {
      url: "/Comissoes/cadastros",
      title: "Cadastros",
      subsTitle: "Gerencie os planos que você vende",
    },
  },

  drive: {
    title: "Drive",

    drive: {
      url: "/drive",
      title: "Drive",
    },
  },

  lancamentos: {
    title: "Lançamentos",

    contratos: {
      url: "/Lancamentos/contratos",
      title: "Contratos",
    },

    contrato: {
      url: "/Lancamentos/contrato/:id",
      title: "Contrato interno",
    },
    contratoParcelasInterno: {
      url: "/Lancamentos/contrato/parcelas/:id",
      title: "Parcelas contrato",
    },

    parcelas: {
      url: "/Lancamentos/parcelas",
      title: "Parcelas",
    },
  },
};
