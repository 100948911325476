import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  ICreateCampaign,
  ICreateGroupLeads,
  ICreateCampaignSteps,
  ICreateInstancesWhats,
} from "../CampaignContextTypes";
import { handleGenerateUuid } from "src/shared/Utils/handleGenerateUuid";

export const valuesDefaultInputsInstancesWhats: ICreateInstancesWhats = {
  title: "",
  description: "",
};

const valuesDefaultSteps: ICreateCampaignSteps = {
  id: handleGenerateUuid(),
  title: "Inicio da apresentação",
  date: `${GetDateUSSemValue()}`,
  time: "18:30",
  message: [""],
};

export const valuesDefaultInputsCampaign: ICreateCampaign = {
  title: "",
  description: "",
  instancesWhatsId: "",
  groupLeadsId: "",
  flows: [valuesDefaultSteps],
};

export const valuesDefaultInputsGroupLeads: ICreateGroupLeads = {
  title: "",
  description: "",
  leads: [],
};
