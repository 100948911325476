import {
  Box,
  Chip,
  Grid,
  Avatar,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CachedIcon from "@mui/icons-material/Cached";

import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { IListInstancesWhats } from "src/Contexts/Campaign/CampaignContextTypes";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { ModalInstancesWhatsQrCode } from "./Components/GetQrCode";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import { useState } from "react";
import { formatString } from "@format-string/core";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ModalDeleteCore } from "src/Pages/components";

interface ICardInstancesWhats {
  data: IListInstancesWhats;
}

export const CardInstancesWhats = ({ data }: ICardInstancesWhats) => {
  const [openModalQrCode, setOpenModalQrCode] = useState(false);

  const { setAttTable } = useConfigPageContext();

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/instancesWhats" });

  return (
    <>
      <ModalInstancesWhatsQrCode
        id={data.id}
        openModal={openModalQrCode}
        setOpenModal={setOpenModalQrCode}
      />

      <ModalDeleteCore
        loading={loadingDelete}
        modalOpen={openModalDelete}
        no="Não, Cancelar"
        titlePrimary="Você deseja excluir da lista ?"
        yes="Sim, Deletar !"
        onClickFalse={() => setOpenModalDelete(false)}
        onClickTrue={() => handleDelete()}
      />

      <Grid item xs={12} lg={4} key={data.id}>
        <CardCore sxStyle={{ margin: 0, mb: "12px", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{data.title}</Typography>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Chip
                label={data.status}
                color={data.status === "open" ? "success" : "error"}
                size="small"
                sx={{ mr: "12px" }}
              />

              {data.status !== "open" && (
                <IconButton
                  sx={{ margin: 0, padding: 0, mr: "12px" }}
                  onClick={() => setOpenModalQrCode(true)}
                >
                  <QrCodeScannerIcon
                    sx={{ cursor: "pointer" }}
                    color={"success"}
                  />
                </IconButton>
              )}

              <IconButton
                sx={{ margin: 0, padding: 0, mr: "12px" }}
                onClick={() => setAttTable((item) => !item)}
              >
                <CachedIcon sx={{ cursor: "pointer" }} />
              </IconButton>

              <IconButton sx={{ margin: 0, padding: 0 }} disabled>
                <ArrowForwardIcon sx={{ cursor: "pointer" }} />
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ margin: "24px 0" }} />

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{ marginRight: "12px" }}
              src={data.urlImage || data.title.toUpperCase()}
            />
            <Box>
              <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                {data.number
                  ? formatString({
                      type: "phone",
                      value: data.number,
                    })
                  : "Número: Ainda não capturado"}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <ButtonCore
              title="Deletar"
              size="small"
              variant="outlined"
              color="error"
              onClick={() => {
                setIdModalDelete(data.id);
                setOpenModalDelete(true);
              }}
            />
          </Box>
        </CardCore>
      </Grid>
    </>
  );
};
