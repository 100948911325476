import { Box, Typography } from "@mui/material";

export const InfoModalContracts = () => {
  return (
    <Box>
      <Typography paragraph>
        A tela de Contratos permite gerenciar os contratos de planos de saúde
        cadastrados no sistema. Aqui, você pode visualizar, editar e armazenar
        informações importantes como <strong>datas</strong>,{" "}
        <strong>dados pessoais</strong>, <strong>arquivos</strong> e{" "}
        <strong>parcelas geradas</strong>.
      </Typography>

      <Typography variant="h6">Criando um Contrato</Typography>
      <Typography component="ol" mb={2}>
        <li>
          Clique no botão <strong>Criar Contrato</strong>.
        </li>
        <li>
          Preencha as informações necessárias, como dados pessoais e detalhes do
          plano.
        </li>
        <li>
          Após a criação do contrato, o sistema irá gerar automaticamente as{" "}
          <strong>parcelas</strong> associadas.
        </li>
        <li>
          Clique em <strong>Salvar</strong> para concluir.
        </li>
      </Typography>

      <Typography variant="h6">Gerenciamento de Parcelas</Typography>
      <Typography paragraph mb={2}>
        As parcelas geradas a partir do contrato podem ser visualizadas tanto na
        tela de <strong>Contratos</strong> quanto na tela de{" "}
        <strong>Parcelas</strong>. Aqui, é possível acompanhar os pagamentos e
        vencimentos.
      </Typography>

      <Typography variant="h6">
        Configuração Obrigatória para Criar um Contrato
      </Typography>
      <Typography component="ul" mb={2}>
        <li>
          <strong>Operadoras cadastradas</strong>
        </li>
        <li>
          <strong>Modalidades cadastradas</strong>
        </li>
        <li>
          <strong>Administradoras cadastradas</strong>
        </li>
        <li>
          <strong>Empresas cadastradas</strong>
        </li>
        <li>
          <strong>Grades de comissão criadas</strong>
        </li>
      </Typography>

      <Typography variant="h6">Acompanhamento e Avisos</Typography>
      <Typography paragraph mb={2}>
        O sistema alerta sobre a <strong>data de vencimento da proposta</strong>
        , garantindo que nenhuma oportunidade seja perdida. Monitore a validade
        dos contratos e tome ações antes do vencimento.
      </Typography>

      <Typography paragraph>
        Com essa tela, você pode gerenciar seus contratos com eficiência e
        garantir o controle total das suas operações!
      </Typography>
    </Box>
  );
};
