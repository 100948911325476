import { alpha, Box, Typography } from "@mui/material";
import { useCallback } from "react";
import {
  DropZoneCore,
  IListPDF,
} from "src/Pages/components/DropZoneCore/DropZoneCore";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { UseFormUploadDrive } from "./Hook/UseFormUploadDrive";

export const UploadDrive = () => {
  const { handleSubmit, loading: loadingSubmit } = UseFormUploadDrive();

  const handleUploadFile = useCallback(async (files: IListPDF[]) => {
    await handleSubmit(files);
  }, []);

  return (
    <DropZoneCore
      onFilesChange={handleUploadFile}
      isLoading={loadingSubmit}
      accept={{
        "image/jpeg": [],
        "image/png": [],
        "image/JPG": [],
        "image/WEBP": [],
        "image/GIF": [],

        "application/pdf": [],
        "application/msword": [],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [],
        "application/vnd.ms-excel": [],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
        "text/plain": [],
      }}
      sxStyle={{
        height: "200px",
        padding: "8px",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",

        background: (theme) => alpha(theme.palette.primary.main, 0.05),

        cursor: loadingSubmit ? "default" : "pointer",
        borderRadius: "12px",
        border: (theme) => `1px dashed ${theme.palette.primary.main}`,

        transition: "background 0.3s ease",
        "&:hover": {
          background: (theme) =>
            loadingSubmit
              ? alpha(theme.palette.primary.main, 0.05)
              : alpha(theme.palette.primary.main, 0.1),
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          marginBottom: "16px",
          background: (theme) => alpha(theme.palette.primary.main, 0.3),
        }}
      >
        <DriveFolderUploadIcon fontSize="large" />
      </Box>
      <Box>
        <Typography>ou arraste seu arquivo</Typography>
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          Clique aqui
        </Typography>
      </Box>
    </DropZoneCore>
  );
};
