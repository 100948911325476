import { CheckCircle } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { LinkCore } from "src/Pages/components/LinkCore/LinkCore";
import { UrlConfig } from "src/shared/Utils/paths";

const benefits = [
  {
    title: "Gestão Completa de Contratos",
    description:
      "Organize e acompanhe contratos de planos de saúde com controle sobre vencimentos, parcelas e documentos em um só lugar.",
  },
  {
    title: "Disparo de Mensagens Automatizado",
    description:
      "Crie campanhas de WhatsApp para leads e clientes, segmentando contatos e aumentando a taxa de conversão.",
  },
  {
    title: "Automação de Relatórios e Notificações",
    description:
      "Receba resumos semanais e mensais por WhatsApp e e-mail, garantindo um acompanhamento eficiente das vendas e comissões.",
  },
  {
    title: "Filtros e Dashboard Inteligentes",
    description:
      "Utilize filtros avançados para encontrar contratos e leads rapidamente, com uma dashboard que fornece insights sobre sua produção e resultados.",
  },
];

export const FirstCongratulations = () => {
  const { setOpenModalFirst } = useAuthContext();

  return (
    <>
      <Grid container spacing={1} direction="row">
        {benefits.map((benefit, index) => (
          <Grid item xs={12} sm={12} md={12} key={index}>
            <CardCore
              sxStyle={{
                height: "98px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "0.1rem",
              }}
            >
              <CheckCircle sx={{ color: "green", marginRight: 2 }} />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                >
                  {benefit.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{ fontSize: { xs: "10px", sm: "13px" } }}
                >
                  {benefit.description}
                </Typography>
              </Box>
            </CardCore>
          </Grid>
        ))}
      </Grid>

      <Box
        onClick={() => setOpenModalFirst(false)}
        sx={{ mb: 2, display: { xs: "block", md: "none" } }}
      >
        <LinkCore
          to={`${UrlConfig.lancamentos.contratos.url}?setOpen=true&firstConfig=true`}
        >
          <ButtonCore sx={{ mt: 2.5 }} title="Vamos lá" fullWidth />
        </LinkCore>
      </Box>
    </>
  );
};
