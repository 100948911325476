import {
  SetStateAction as Action,
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  FC,
  ReactNode,
} from "react";
import { getMenu } from "src/shared/setup/API/api";

type IHandleError = { message: string; status?: string };

type AuthContextType = {
  setOpenAside: Dispatch<Action<boolean>>;
  openAside: boolean;
  setDelayedOpen: Dispatch<Action<boolean>>;
  delayedOpen: boolean;
  setOPenAlert: Dispatch<Action<boolean>>;
  openAlert: boolean;
  setMessageAlert: Dispatch<Action<string>>;
  messageAlert: string;
  setStatusAlert: Dispatch<Action<string | undefined>>;
  statusAlert: string | undefined;
  setChangePassword: Dispatch<Action<string>>;
  changePassword: string;

  setValueWhatsUpdate: Dispatch<Action<string>>;
  valueWhatsUpdate: string;

  handleGetAlert({ message, status }: IHandleError): void;
};

const LayoutMainProvider = createContext({} as AuthContextType);

export const LayoutMainProviderContext: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [valueWhatsUpdate, setValueWhatsUpdate] = useState("");
  const [changePassword, setChangePassword] = useState("password");
  const [messageAlert, setMessageAlert] = useState("");
  const [statusAlert, setStatusAlert] = useState<string | undefined>("");
  const [delayedOpen, setDelayedOpen] = useState(false);
  const [openAlert, setOPenAlert] = useState(false);

  const [openAside, setOpenAside] = useState<boolean>(() => {
    const open = localStorage.getItem(getMenu);

    if (open === "true") return !!open;
    return false;
  });

  useEffect(
    () => localStorage.setItem(getMenu, JSON.stringify(openAside)),
    [openAside]
  );

  useEffect(() => {
    if (openAside) {
      const timer = setTimeout(() => {
        setDelayedOpen(true);
      }, 180); // 100ms segundo de delay

      return () => clearTimeout(timer);
    } else {
      setDelayedOpen(false);
    }
  }, [openAside]);

  const handleGetAlert = ({ message, status }: IHandleError) => {
    setMessageAlert(message);
    setOPenAlert(true);
    setStatusAlert(status);
  };

  return (
    <LayoutMainProvider.Provider
      value={{
        setValueWhatsUpdate,
        setChangePassword,
        valueWhatsUpdate,
        setMessageAlert,
        setStatusAlert,
        setDelayedOpen,
        handleGetAlert,
        changePassword,
        setOPenAlert,
        setOpenAside,
        messageAlert,
        delayedOpen,
        statusAlert,
        openAside,
        openAlert,
      }}
    >
      {children}
    </LayoutMainProvider.Provider>
  );
};

export const useLayoutMainContext = (): AuthContextType =>
  useContext(LayoutMainProvider);
