import { ModalContentCore } from "../ModalCoreBase/Components/ModalContentCore";
import {
  Box,
  Grid,
  Step,
  Modal,
  Divider,
  Stepper,
  useTheme,
  StepLabel,
  IconButton,
  Typography,
  StepContent,
  useMediaQuery,
} from "@mui/material";
import { ModalCommissions } from "src/Pages/TelasSistemas/Commissions/GridCommissions/components/Form";
import { useEffect, useState } from "react";
import { CardCore } from "../CardCore/CardCore";
import { FormConfirmationsData } from "./Components/FormConfirmationsData/FormConfirmationsData";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { CloseIcon } from "../Icons/Icons";
import { useAppThemeContext } from "src/Contexts/ThemeContextConfig";
import { FirstCongratulations } from "./Components/FormUpdateUser/FormUpdateUser";
import { LinkCore } from "../LinkCore/LinkCore";
import { UrlConfig } from "src/shared/Utils/paths";
import { getLinkWhatApp } from "../CardPlans/CardPlans";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { stepsFirstConfig } from "./Components/steps";
import { ButtonCore } from "../ButtonCore/ButtonCore";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import { api } from "src/shared/setup/API/api";

export const ModalFirstConfig = () => {
  const { setUserPerfil, userPerfil, setOpenModalFirst, openModalFirst } =
    useAuthContext();

  if (!userPerfil) return null;

  const { themeName } = useAppThemeContext();

  const [activeStep, setActiveStep] = useState(0);

  const isBreakPointMd = useMediaQuery(useTheme().breakpoints.up("md"));

  const objConfigFirstModal = userPerfil.firstConfig;

  const stepsConfig: (keyof typeof objConfigFirstModal)[] = [
    "introduction",
    "firstGrid",
    "firstContract",
  ];

  // Garante que `initialStep` sempre seja um número
  const initialStep = stepsConfig.findIndex(
    (step) => !objConfigFirstModal[step]
  );

  useEffect(() => {
    if (initialStep !== -1) {
      setActiveStep(initialStep);
    }
  }, [initialStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleUpdateConfig = () => {
    handleNext();
    api
      .patch("/users/firstConfig", {
        firstConfig: {
          ...userPerfil?.firstConfig,
          firstGrid: true,
        },
      })
      .then(() => {
        if (userPerfil) {
          setUserPerfil(() => ({
            ...userPerfil,
            firstConfig: {
              ...userPerfil?.firstConfig,
              firstGrid: true,
            },
          }));
        }
      });
  };

  return (
    <>
      {initialStep !== -1 && (
        <>
          <Box sx={{ display: "flex", alignItems: "center", mb: "12px" }}>
            <SettingsSuggestOutlinedIcon
              sx={{ marginRight: "8px" }}
              color="warning"
            />

            <Typography
              sx={{
                color: "#f99500",
                fontWeight: 500,
                marginRight: { xs: "12px", sm: "16px" },
                fontSize: { xs: "12px", sm: "16px" },
              }}
            >
              Finalize suas configurações iniciais - {initialStep}/
              {stepsConfig.length}
            </Typography>

            <ButtonCore
              title="Configurar"
              size="small"
              color="warning"
              variant="outlined"
              onClick={() => setOpenModalFirst(true)}
            />
          </Box>

          <Modal open={openModalFirst}>
            <ModalContentCore
              sx={{
                padding: { xs: "12px", sm: "24px" },
                maxWidth: "95vw",
                maxHeight: "95vh",
                overflow: "auto",
                width: { xs: "500px", sm: "500px", md: "950px" },
                background: themeName === "dark" ? "#353741" : "#ffffff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "12px",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "20px", md: "24px" },
                    fontWeight: "500",
                    marginLeft: { xs: 0, md: "24px" },
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  Bem-Vindo ao Correctors 👋
                </Typography>

                <IconButton
                  aria-label="close"
                  onClick={() => setOpenModalFirst(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Grid container spacing={isBreakPointMd ? 6 : 1}>
                <Grid item xs={12} md={5}>
                  <Stepper
                    activeStep={activeStep}
                    orientation={isBreakPointMd ? "vertical" : "horizontal"}
                    sx={{ marginBottom: "24px" }}
                  >
                    {stepsFirstConfig.map((step, index) => (
                      <Step key={step.label}>
                        <CardCore
                          elevation={2}
                          sx={{ padding: "12px", cursor: "pointer" }}
                        >
                          <StepLabel onClick={() => setActiveStep(index)}>
                            {isBreakPointMd && (
                              <Typography
                                sx={{ fontSize: { xs: "12px", sm: "16px" } }}
                              >
                                {step.label}
                              </Typography>
                            )}
                          </StepLabel>

                          <StepContent sx={{ border: 0 }}>
                            {isBreakPointMd && (
                              <Typography
                                sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                              >
                                {step.description}
                              </Typography>
                            )}

                            {index === stepsFirstConfig.length - 1 &&
                              isBreakPointMd && (
                                <Box
                                  onClick={() => setOpenModalFirst(false)}
                                  sx={{ mb: 2 }}
                                >
                                  <LinkCore
                                    to={`${UrlConfig.lancamentos.contratos.url}?setOpen=true&firstConfig=true`}
                                  >
                                    <ButtonCore
                                      sx={{ mt: 2.5 }}
                                      title="Vamos lá"
                                      fullWidth
                                    />
                                  </LinkCore>
                                </Box>
                              )}
                          </StepContent>
                        </CardCore>
                      </Step>
                    ))}
                  </Stepper>

                  {isBreakPointMd && (
                    <Box>
                      <Divider sx={{ margin: "12px 0" }} />

                      <Box
                        sx={{
                          padding: "0 12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          Estamos aqui para responder às suas perguntas
                        </Typography>

                        <IconButton
                          href={getLinkWhatApp(
                            "5511984939024",
                            `Olá, me chamo ${userPerfil?.name}, tenho uma duvida: .`
                          )}
                          target="_blank"
                          sx={{
                            background: "#72f76962",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          <WhatsAppIcon
                            color="success"
                            sx={{ color: "#43d400e9" }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} md={7}>
                  <Box
                    sx={{
                      maxHeight: "75vh",
                      overflow: "auto",
                      paddingRight: "12px",
                    }}
                  >
                    {activeStep === 0 && (
                      <FormConfirmationsData setActiveStep={setActiveStep} />
                    )}

                    {activeStep === 1 && (
                      <CardCore sxStyle={{ margin: "0.1rem" }}>
                        <Typography mb={2}>Comissões</Typography>
                        <ModalCommissions
                          titleSubmit="Avançar"
                          handleSummitUpdate={handleUpdateConfig}
                          idCommission=""
                        />
                      </CardCore>
                    )}

                    {activeStep === 2 && <FirstCongratulations />}
                  </Box>
                </Grid>
              </Grid>
            </ModalContentCore>
          </Modal>
        </>
      )}
    </>
  );
};
