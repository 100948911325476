import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { CardAlerts } from "src/Pages/components/CardAlerts/CardAlerts";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { valuesDefaultInputsCommissions } from "src/Contexts/comissoes/ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { api } from "src/shared/setup/API/api";

export interface CommissionInstallment {
  code: number;
  parcelNumber: number;
  valueCommission: number;
}

interface Data {
  code: number;
  isActive: boolean;
  idTenant: string;
  createdAt: string;
  updatedAt: string;
  effectiveDate: string;
  operatorCode: number;
  modalityCode: number;
  companyCode: number;
  administratorCode: number | null;
  commissionInstallments: CommissionInstallment[];
}

export interface ApiResponseSEtDataGrid {
  data: Data;
}

interface ICardPercent {
  operatorCode: string;
  modalityCode: string;
  companyCode: string;
  commissionInstallments: CommissionInstallment[] | undefined;
  setOpenModalGrid: React.Dispatch<React.SetStateAction<boolean>>;
  setDataGrid: React.Dispatch<
    React.SetStateAction<ApiResponseSEtDataGrid | undefined>
  >;
  openModalGrid: boolean;
}
export const CardPercent = ({
  commissionInstallments,
  // companyCode,
  // modalityCode,
  // operatorCode,
  setDataGrid,
  setOpenModalGrid,
  openModalGrid,
}: ICardPercent) => {
  const { id } = useConfigPageContext();
  const { valuesInputsContrato, setValuesInputsContrato, valuesGet } =
    useContextContrato();
  const {
    companyCode,
    operatorCode,
    modalityCode,
    administratorCode,
    subscriptionDate,
  } = valuesInputsContrato;

  const { setValuesInputsCommissions } = useContextComissoes();

  useEffect(() => {
    setValuesInputsCommissions({
      ...valuesDefaultInputsCommissions,
      effectiveDate: subscriptionDate,
      administratorCode,
      operatorCode,
      modalityCode,
      companyCode,
    });

    if (
      !id &&
      companyCode &&
      operatorCode &&
      modalityCode &&
      subscriptionDate
    ) {
      api
        .post("/commissions/grid-commission", {
          companyCode: Number(companyCode),
          operatorCode: Number(operatorCode),
          modalityCode: Number(modalityCode),
          administratorCode: administratorCode
            ? Number(administratorCode)
            : null,
          effectiveDate: subscriptionDate,
        })
        .then((res) => {
          setDataGrid(res.data);
        });
    }

    if (
      id &&
      (valuesInputsContrato.operatorCode !== valuesGet.operatorCode ||
        valuesInputsContrato.modalityCode !== valuesGet.modalityCode ||
        valuesInputsContrato.administratorCode !==
          valuesGet.administratorCode ||
        valuesInputsContrato.companyCode !== valuesGet.companyCode ||
        valuesInputsContrato.subscriptionDate !== valuesGet.subscriptionDate) &&
      companyCode &&
      operatorCode &&
      modalityCode &&
      subscriptionDate
    ) {
      setValuesInputsContrato((eventPrev) => ({
        ...eventPrev,
        commissions: undefined,
      }));
      api
        .post("/commissions/grid-commission", {
          companyCode: Number(companyCode),
          operatorCode: Number(operatorCode),
          modalityCode: Number(modalityCode),
          administratorCode: administratorCode
            ? Number(administratorCode)
            : null,
          effectiveDate: subscriptionDate,
        })
        .then((res) => {
          setDataGrid(res.data);
        });
    }
    if (
      id &&
      valuesInputsContrato.operatorCode === valuesGet.operatorCode &&
      valuesInputsContrato.modalityCode === valuesGet.modalityCode &&
      valuesInputsContrato.administratorCode === valuesGet.administratorCode &&
      valuesInputsContrato.companyCode === valuesGet.companyCode &&
      valuesInputsContrato.subscriptionDate === valuesGet.subscriptionDate &&
      companyCode &&
      operatorCode &&
      modalityCode &&
      subscriptionDate
    ) {
      setValuesInputsContrato((prevValue) => ({
        ...prevValue,
        commissions: valuesGet.commissions,
      }));
    }
  }, [
    companyCode,
    operatorCode,
    modalityCode,
    openModalGrid,
    subscriptionDate,
    administratorCode,
  ]);

  return (
    <>
      {operatorCode && modalityCode && companyCode && (
        <Box sx={{ display: "flex" }}>
          {!commissionInstallments ? (
            <CardAlerts
              title="Não tem grade de comissão para essa configuração"
              titleRequired="Obrigatório *"
              sxStyle={{ margin: "16px 0 0 0" }}
              color="warning"
              action={
                <ButtonCore
                  title="Criar comissão"
                  size="small"
                  onClick={() => {
                    setOpenModalGrid(true);
                  }}
                />
              }
            />
          ) : (
            commissionInstallments
              .filter((item) => item.valueCommission > 0)
              .map((item) => {
                return (
                  <Box
                    sx={{
                      marginTop: "12px",
                      border: "1px solid gray",
                      textAlign: "center",
                      borderRadius: "8px",
                      marginRight: "8px",
                      padding: "8px",
                      width: "70px",
                    }}
                  >
                    <Typography
                      sx={{ color: (theme) => theme.palette.text.primary }}
                    >
                      {item.valueCommission}%
                    </Typography>
                  </Box>
                );
              })
          )}
        </Box>
      )}
    </>
  );
};
