import { useEffect, useState } from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useContextViews } from "src/Contexts/Views/contextViews";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  FileOpenIcon,
  PeopleIcon,
  PercentIcon,
} from "src/Pages/components/Icons/Icons";
import { UrlConfig } from "src/shared/Utils/paths";

import { AsideMenuLeft } from "./AsideMenuLeft";
import { BaseMenuAside } from "./componentes/BaseMenuAside";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { Divider } from "@mui/material";

interface ICollapseState {
  open4: boolean;
  open5: boolean;
  open6: boolean;
  open7: boolean;
  open8: boolean;
}

export const AsideTitle = () => {
  const { openAside, setOpenAside } = useLayoutMainContext();
  const { NavigationComissoes, navigationCampaign } = useContextViews();

  const [collapseState, setCollapseState] = useState<ICollapseState>({
    open4: false,
    open5: false,
    open6: false,
    open7: false,
    open8: false,
  });

  const [prevState, setPrevState] = useState<ICollapseState>({
    open4: false,
    open5: false,
    open6: false,
    open7: false,
    open8: false,
  });

  useEffect(() => {
    if (!openAside) {
      setPrevState({ ...collapseState });
      setCollapseState({
        open4: false,
        open5: false,
        open6: false,
        open7: false,
        open8: false,
      });
    } else {
      setCollapseState({ ...prevState });
    }
  }, [openAside]);

  const handleToggle = (key: keyof ICollapseState) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
    setOpenAside(true);
  };

  return (
    <>
      <AsideMenuLeft
        icon={<BarChartIcon fontSize="small" />}
        title="Dashboard"
        to={UrlConfig.dashboard.url}
      />

      <BaseMenuAside
        Navigation={NavigationComissoes}
        icon={<PercentIcon fontSize="small" />}
        open={collapseState.open5}
        setOpen={() => handleToggle("open5")}
        title={UrlConfig.comissao.title}
      />
      <AsideMenuLeft
        icon={<FindInPageIcon fontSize="small" />}
        title="Contratos"
        to={UrlConfig.lancamentos.contratos.url}
      />
      <AsideMenuLeft
        icon={<FileOpenIcon fontSize="small" />}
        title="Parcelas"
        to={UrlConfig.lancamentos.parcelas.url}
      />

      <Divider sx={{ margin: "8px 0" }} />

      <AsideMenuLeft
        icon={<PeopleIcon fontSize="small" />}
        title={UrlConfig.leads.title}
        to={UrlConfig.leads.leads.url}
      />

      <BaseMenuAside
        Navigation={navigationCampaign}
        icon={<WhatsAppIcon fontSize="small" />}
        open={collapseState.open7}
        setOpen={() => handleToggle("open7")}
        title={UrlConfig.campaign.title}
      />

      <AsideMenuLeft
        icon={<CloudDownloadIcon fontSize="small" />}
        title={UrlConfig.drive.title}
        to={UrlConfig.drive.drive.url}
      />
    </>
  );
};
