export const stepsFirstConfig = [
  {
    label: "Introdução",
    description: `Atualizando seus dados para receber relatórios automatizados 🤖`,
  },
  {
    label: "Sua primeira Grade de Comissão",
    description: `Crie uma grade para que o sistema calcule suas comissões`,
  },
  {
    label: "Seus Contratos de forma Simplificada",
    description: "Você está Pronto para o seu primeiro Contrato",
  },
];
