import { useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseFormModalCancelContract = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const [qrCode, setQrCode] = useState("");

  const handleGetQrCode = async (id: string) => {
    setLoading(true);

    await api
      .get(`/instancesWhats/QrCode/${id}`)
      .then((res) => {
        setQrCode(res.data.qrCode);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleGetQrCode, loading, qrCode, setQrCode };
};
