import { Box, Paper, useTheme, useMediaQuery } from "@mui/material";
import { HeaderIsPlanExpired } from "src/Pages/components/HeaderIsPlanExpired/HeaderIsPlanExpired";

import { PrivateRoutes } from "../Routes/PrivateRoutes";

import { CardMain } from "./Drawers/Components/CardMain";
import { DrawerDesktop } from "./Drawers/DrawerDesktop";
import { DrawerMobile } from "./Drawers/DrawerMobile";
import { useAppThemeContext } from "src/Contexts/ThemeContextConfig";

export const Master = () => {
  const theme = useTheme();
  const { themeName } = useAppThemeContext();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ position: "relative" }}>
      <Paper
        elevation={0}
        sx={{
          minHeight: "100vh",
          background: (theme) =>
            themeName === "light" ? "#f8f8f8" : theme.palette.background.paper,
        }}
      >
        {!matches ? <DrawerMobile /> : <DrawerDesktop />}

        <CardMain>
          <PrivateRoutes />
        </CardMain>
      </Paper>

      <Box sx={{ zIndex: 999, position: "fixed", bottom: 0, width: "100%" }}>
        <HeaderIsPlanExpired />
      </Box>
    </Box>
  );
};
