import { useEffect } from "react";
import { ModalCore } from "src/Pages/components";

import { FormCancelContract } from "./FormInstancesWhatsQrCode";
import { UseFormModalCancelContract } from "./hook/UseFormInstancesWhatsQrCode";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";

interface IModalInstancesWhatsQrCode {
  id: string;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
}

export const ModalInstancesWhatsQrCode = ({
  id,
  setOpenModal,
  openModal,
}: IModalInstancesWhatsQrCode) => {
  const { setAttTable } = useConfigPageContext();

  const {
    loading: loadingGetQrCode,
    handleGetQrCode,
    setQrCode,
    qrCode,
  } = UseFormModalCancelContract();

  useEffect(() => {
    if (openModal) {
      handleGetQrCode(id);
    } else {
      setQrCode("");
      setAttTable((item) => !item);
    }
  }, [openModal]);

  return (
    <>
      <ModalCore
        loadingSubmit={loadingGetQrCode}
        open={openModal}
        setOpen={setOpenModal}
        sxStyle={{ width: "500px" }}
        title="Conexão com QrCode"
      >
        <FormCancelContract qrCode={qrCode} loading={loadingGetQrCode} />
      </ModalCore>
    </>
  );
};
