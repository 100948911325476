import { Box, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import { useLayoutMainContext } from "../../Contexts/MainLayoutContext";

import { AsideTitle } from "./aside/AsideTitle";
import { ToggleOpenMenu } from "../Header/components/ToggleOpenMenu/ToggleOpenMenu";
import { LogoHeader } from "../Header/components/LogoHeader/LogoHeader";
import { HeaderIsPlanTest } from "src/Pages/components/HeaderIsPlanTest/HeaderIsPlanTest";

export const DrawerMobile = () => {
  const { openAside, setOpenAside } = useLayoutMainContext();

  return (
    <Drawer open={openAside} onClose={() => setOpenAside(!openAside)}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "8px 12px",
        }}
      >
        <ToggleOpenMenu />
        <LogoHeader />
      </Typography>
      <List
        sx={{
          minWidth: "220px",
          height: "100%",
          padding: "12px",
          mb: 2,
        }}
      >
        <AsideTitle />
      </List>

      {openAside && (
        <Box sx={{ width: "260px" }}>
          <HeaderIsPlanTest />
        </Box>
      )}
    </Drawer>
  );
};
