import React, { useEffect } from "react";
import { ModalCore } from "src/Pages/components";

import { FormCreateInstances } from "./FormCreateInstances";
import { UseFormInstancesWhats } from "./hook/UseFormInstancesWhats";
import { ModalInstancesWhatsQrCode } from "../Table/Components/CardInstancesWhats/Components/GetQrCode";
import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { valuesDefaultInputsInstancesWhats } from "src/Contexts/Campaign/ValuesDefaultInputsCampaign/valuesDefaultInputsInstancesWhats";

interface IModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalCampaignNewInstance = ({
  openModal,
  setOpenModal,
}: IModal) => {
  const { setValuesInputsInstancesWhats } = useContextCampaign();

  const {
    handleSubmit,
    loading: loadingSubmit,
    instanceId,
    openQrCodeModal,
    setOpenQrCodeModal,
  } = UseFormInstancesWhats({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal)
      setValuesInputsInstancesWhats(valuesDefaultInputsInstancesWhats);
  }, [openModal]);

  return (
    <>
      {instanceId && (
        <ModalInstancesWhatsQrCode
          id={instanceId}
          openModal={openQrCodeModal}
          setOpenModal={setOpenQrCodeModal}
        />
      )}

      <ModalCore
        open={openModal}
        setOpen={setOpenModal}
        loadingSubmit={loadingSubmit}
        sxStyle={{ width: "550px" }}
        title="Conectar novo whats"
        titleSubmit="Salvar / Gerar QRcode"
        onChange={handleSubmit}
      >
        <FormCreateInstances />
      </ModalCore>
    </>
  );
};
