import { useState } from "react";
import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseFormCommissions {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UseFormInstancesWhats = ({
  setOpenModal,
}: IUseFormCommissions) => {
  const { valuesInputsInstancesWhats } = useContextCampaign();
  const { setAttTable, id } = useConfigPageContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
  const [instanceId, setInstanceId] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const { title, description } = valuesInputsInstancesWhats;

  const gridCommissions = {
    title,
    description,
  };

  const handleCreate = async () => {
    setLoading(true);

    return api
      .post("/instancesWhats", { ...gridCommissions })
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setAttTable((item) => !item);
        setOpenModal(false);

        setInstanceId(res.data.id);
        setOpenQrCodeModal(true);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleEdit = async () => {
    setLoading(true);

    return api
      .put(`/instancesWhats/${id}`, { ...gridCommissions })
      .then((res) => {
        setAttTable((item) => !item);
        handleGetAlert({ message: res.data.message });
        setOpenModal(false);
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return {
    handleSubmit,
    loading,
    instanceId,
    openQrCodeModal,
    setOpenQrCodeModal,
  };
};
