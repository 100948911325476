import { apenasNumeros } from "../Utils/FormatMoney";
import { useState } from "react";
import axios from "axios";

export const AxiosEvolutionClientHttpTestExistsWhats = () =>
  axios.create({
    baseURL:
      "https://devevolution-evolution2.k7zxc6.easypanel.host/chat/whatsappNumbers/Correctors - Atendimento IA",
    headers: {
      "Content-Type": "application/json",
      apikey: "E91A474E9CBB-4EED-95CA-DC48CEBCFF64",
    },
  });

export const useIsValidNumberWhats = () => {
  const [isWhatsValid, setIsWhatsValid] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handle = async (number: string) => {
    const whats = apenasNumeros(number);

    if (whats && whats.length === 11) {
      setIsLoading(true);
      const { data } = await AxiosEvolutionClientHttpTestExistsWhats()
        .post("", { numbers: [`55${whats}`] })
        .finally(() => setIsLoading(false));

      if (data[0]?.exists) {
        setIsWhatsValid(true);
      } else {
        setIsWhatsValid(false);
      }
    }
  };

  return { handle, isWhatsValid, isLoading };
};
