import { IDataTh, ThCoreIndex } from "src/Pages/components";

export const THeadCampaign = () => {
  const dataTh: IDataTh[] = [
    {
      title: "Ações",
      width: "40px",
      align: "left",
    },
    {
      title: "Iniciar/Pausar",
      align: "center",
      width: "50px",
      minWidth: "50px",
    },
    {
      title: "Nome da campanha",
      align: "left",
      width: "170px",
      minWidth: "170px",
    },
    {
      title: "Grupo de lead",
      align: "left",
      width: "170px",
      minWidth: "170px",
    },
    {
      title: "Número de disparo",
      align: "left",
      width: "50px",
    },
    {
      title: "Processo",
      align: "left",
      width: "120px",
      minWidth: "120px",
    },
    {
      title: "Próximo disparo",
      align: "center",
      width: "170px",
      minWidth: "170px",
    },

    {
      title: "Data de criação",
      align: "left",
      width: "100px",
      minWidth: "100px",
    },
  ];

  return <ThCoreIndex dataTh={dataTh} />;
};
