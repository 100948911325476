import React, { useEffect } from "react";
import {
  alpha,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  HttpGetLeadsAll,
  IGetLeadsAll,
} from "src/shared/Hooks/Https/HttpGetLeadsAll";

import { formatString } from "@format-string/core";
import { styled } from "@mui/material/styles";
import {
  OBjLeadsInterestLevel,
  objLeadsStatus,
} from "src/Pages/components/ObjTransform";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { InfoOutlined } from "@mui/icons-material";
import { handleIfLimitCharacter } from "src/shared/Utils/LimitText";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 500,
  },
});

// Funções de manipulação
function not(a: readonly IGetLeadsAll[], b: readonly IGetLeadsAll[]) {
  return a.filter((value) => !b.some((item) => item.id === value.id));
}

function intersection(a: readonly IGetLeadsAll[], b: readonly IGetLeadsAll[]) {
  return a.filter((value) => b.some((item) => item.id === value.id));
}

function union(a: readonly IGetLeadsAll[], b: readonly IGetLeadsAll[]) {
  return [...a, ...not(b, a)];
}

export const FormLeads = () => {
  const { data: listAllLeads } = HttpGetLeadsAll();
  const { valuesInputsGroupLeads, setValuesInputsGroupLeads } =
    useContextCampaign();

  const [left, setLeft] = React.useState<IGetLeadsAll[]>(listAllLeads || []);

  const [checked, setChecked] = React.useState<IGetLeadsAll[]>([]);

  const [right, setRight] = React.useState<IGetLeadsAll[]>([]);

  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

  useEffect(() => {
    setLeft(
      listAllLeads
        .filter(
          (lead) =>
            !valuesInputsGroupLeads.leads.some(
              (removeLead) => removeLead.id === lead.id
            )
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [listAllLeads, valuesInputsGroupLeads]);

  useEffect(() => {
    setRight(
      valuesInputsGroupLeads.leads.sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [listAllLeads]);

  useEffect(() => {
    setValuesInputsGroupLeads((prevValue) => ({
      leads: right,
      description: prevValue.description,
      title: prevValue.title,
    }));
    setRight((prevValue) =>
      prevValue.sort((a, b) => a.name.localeCompare(b.name))
    );
  }, [right]);

  // Para controlar os leads selecionados
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // Função para alternar a seleção
  const handleToggle = (value: IGetLeadsAll) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // Contar a quantidade de leads selecionados
  const numberOfChecked = (items: readonly IGetLeadsAll[]) =>
    intersection(checked, items).length;

  // Selecionar/desmarcar todos os itens
  const handleToggleAll = (items: readonly IGetLeadsAll[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const customList = (
    title: React.ReactNode,
    items: readonly IGetLeadsAll[]
  ) => (
    <Card sx={{ margin: 0, width: "100%" }}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionado`}
      />

      <Divider />

      <List
        sx={{
          width: "100%",
          height: 350,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items?.map((value: IGetLeadsAll) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography>{value.name}</Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        {handleIfLimitCharacter({
                          LIMIT: 22,
                          value: value.email,
                        })}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        {formatString({
                          type: "phone",
                          value: value.whats,
                        })}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip title="Nível de interesse">
                        <Chip
                          label={
                            OBjLeadsInterestLevel[value.interestLevel].title
                          }
                          size="small"
                          style={{
                            marginRight: 8,
                            background: alpha(
                              OBjLeadsInterestLevel[value.interestLevel].color,
                              0.3
                            ),
                          }}
                        />
                      </Tooltip>
                      <CustomWidthTooltip
                        title={
                          <Box>
                            <Typography>{value.name}</Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: (theme) => theme.palette.text.secondary,
                              }}
                            >
                              {value.email}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: (theme) => theme.palette.text.secondary,
                              }}
                            >
                              {formatString({
                                type: "phone",
                                value: value.whats,
                              })}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: (theme) => theme.palette.text.secondary,
                              }}
                            >
                              {objLeadsStatus[value.status]}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: (theme) => theme.palette.text.secondary,
                              }}
                            >
                              {FormatDateBR(value.captureAt)}
                            </Typography>
                          </Box>
                        }
                      >
                        <IconButton onClick={(e) => e.stopPropagation()}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Box>
                  </Box>
                }
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} sx={{ alignItems: "center" }}>
      <Grid xs={12} md={5.3} item>
        {customList("Leads", left)}
      </Grid>

      <Grid xs={12} md={1.4} item>
        <Grid
          container
          direction={isMobile ? "row" : "column"}
          sx={{ alignItems: "center", justifyContent: "center", gap: 1 }}
        >
          <Tooltip title="Colocar na lista da direita">
            <Button
              sx={{ my: 0.5 }}
              variant="contained"
              size="medium"
              onClick={() => {
                setRight(
                  right.concat(
                    leftChecked.sort((a, b) => a.name.localeCompare(b.name))
                  )
                );
                setLeft(
                  not(
                    left.sort((a, b) => a.name.localeCompare(b.name)),
                    leftChecked.sort((a, b) => a.name.localeCompare(b.name))
                  )
                );
                setChecked(not(checked, leftChecked));
              }}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <KeyboardDoubleArrowRightIcon
                sx={{ transform: isMobile ? "rotate(90deg)" : "none" }}
              />
            </Button>
          </Tooltip>

          <Tooltip title="Remover da lista">
            <Button
              sx={{ my: 0.5 }}
              variant="contained"
              size="medium"
              onClick={() => {
                setLeft(
                  left.concat(
                    rightChecked.sort((a, b) => a.name.localeCompare(b.name))
                  )
                );
                setRight(
                  not(
                    right.sort((a, b) => a.name.localeCompare(b.name)),
                    rightChecked.sort((a, b) => a.name.localeCompare(b.name))
                  )
                );
                setChecked(not(checked, rightChecked));
              }}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              <KeyboardDoubleArrowLeftIcon
                sx={{ transform: isMobile ? "rotate(90deg)" : "none" }}
              />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid xs={12} md={5.3} item>
        {customList("Leads (Pertence ao Grupo)", right)}
      </Grid>
    </Grid>
  );
};
