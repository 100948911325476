import { ChangeEvent, useEffect, useState } from "react";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import { AppTextField } from "src/Pages/components";

import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { ICreateCampaign } from "src/Contexts/Campaign/CampaignContextTypes";
import { HttpGetInstancesWhatsAll } from "src/shared/Hooks/Https/HttpGetInstancesWhatsAll";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";
import { HttpGetGroupLeadsAll } from "src/shared/Hooks/Https/HttpGetGroupLeadsAll";
import { CardAlerts } from "src/Pages/components/CardAlerts/CardAlerts";
import { ModalCampaignNewInstance } from "../../../Instances/Components/FormCreateInstances";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { AddIcon } from "src/Pages/components/Icons/Icons";
import { ModalGroupLeads } from "src/Pages/TelasSistemas/GroupLeads/Page/Form";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";

interface IFormContrato {
  inputRef: React.RefObject<HTMLInputElement>;
}

export const FormContrato = ({ inputRef }: IFormContrato) => {
  const { valuesInputsCampaign, setValuesInputsCampaign } =
    useContextCampaign();

  const { data: resultListInstancesWhats, setRefresh: setRefreshInstances } =
    HttpGetInstancesWhatsAll();

  const { data: resultListGroupsLeads, setRefresh: setRefreshGroupLeads } =
    HttpGetGroupLeadsAll();

  const [openModalGroupLeads, setOpenModalGroupLeads] = useState(false);
  const [openModalInstance, setOpenModalInstance] = useState(false);

  useEffect(() => {
    setRefreshInstances((item) => item + 1);
  }, [openModalInstance]);

  useEffect(() => {
    setRefreshGroupLeads((item) => item + 1);
  }, [openModalGroupLeads]);

  const handleChange =
    (prop: keyof ICreateCampaign) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsCampaign((eventPrev) => ({
        ...eventPrev,
        [prop]: event.target.value,
      }));
    };

  return (
    <>
      <ModalGroupLeads
        openModal={openModalGroupLeads}
        setOpenModal={setOpenModalGroupLeads}
      />

      <ModalCampaignNewInstance
        openModal={openModalInstance}
        setOpenModal={setOpenModalInstance}
      />

      {resultListInstancesWhats.length === 0 && (
        <CardAlerts
          titleRequired="Obrigatório *"
          title="Não tem Número de WhatsApp conectado"
          sxStyle={{ margin: "0 0 16px 0" }}
          color="warning"
          action={
            <ButtonCore
              startIcon={<AddIcon />}
              title="Novo WhatsApp"
              size="small"
              onClick={() => setOpenModalInstance(true)}
            />
          }
        />
      )}
      {resultListGroupsLeads.length === 0 && (
        <CardAlerts
          titleRequired="Obrigatório *"
          title="Não tem Grupo de Leads criado"
          sxStyle={{ margin: "0 0 16px 0" }}
          color="warning"
          action={
            <ButtonCore
              startIcon={<AddIcon />}
              title="Criar Grupo de Leads"
              size="small"
              onClick={() => setOpenModalGroupLeads(true)}
            />
          }
        />
      )}

      <DividerCore title="Dados da campanha" sxStyle={{ marginTop: "0" }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <AppTextField
            label="Nome da campanha *"
            fullWidth
            placeholder="Nome da campanha"
            inputRef={inputRef}
            value={valuesInputsCampaign.title}
            onChange={handleChange("title")}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <AppTextField
            fullWidth
            select
            label="Numero de envio *"
            value={valuesInputsCampaign.instancesWhatsId}
            onChange={handleChange("instancesWhatsId")}
          >
            {resultListInstancesWhats.map((option) => (
              <MenuItem key={option.id} value={option.id} sx={{ mb: "8px" }}>
                <Box>
                  <Typography sx={{ mb: "4px" }}>{option.title}</Typography>

                  <Typography sx={{ fontSize: "13px" }}>
                    {option.number
                      ? cpfOurCnpj({ text: option.number, type: "phone" })
                      : "Não conectado"}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </AppTextField>
        </Grid>

        <Grid item xs={6} md={4}>
          <AppTextField
            fullWidth
            select
            label="Grupo de leads"
            value={valuesInputsCampaign.groupLeadsId}
            onChange={handleChange("groupLeadsId")}
          >
            {resultListGroupsLeads.map((option) => (
              <MenuItem key={option.id} value={option.id} sx={{ mb: "8px" }}>
                <Box>
                  <Typography sx={{ mb: "4px" }}>{option.title}</Typography>

                  <Typography sx={{ fontSize: "13px" }}>
                    Total de Leads: {option._count.GroupManyLeads}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </AppTextField>
        </Grid>
      </Grid>
    </>
  );
};
