import { useState } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { IUsers } from "src/Contexts/AuthContext/AuthContextTypes";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";
import { IListPDF } from "src/Pages/components/DropZoneCore/DropZoneCore";

export const UseFormUploadAvatar = () => {
  const { handleGetAlert } = useLayoutMainContext();
  const { setUserPerfil } = useAuthContext();

  const [loading, setLoading] = useState(false);

  const handleUpdateAvatar = (files: IListPDF[]) => {
    const formData = new FormData();

    files.forEach((file) => {
      const blob = new Blob([file.pdf], { type: file.type });
      formData.append("image", blob, file.name);
    });

    setLoading(true);

    return api
      .post(`/registers/upload-avatar`, formData)
      .then(({ data }) => {
        setUserPerfil((prev) => ({
          ...(prev as IUsers),
          avatar: {
            url: data.url,
          },
        }));
        handleGetAlert({ message: data.message });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleUpdateAvatar, loading, setLoading };
};
