import { Box, CardMedia, Typography } from "@mui/material";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

export const CardCopy = () => {
  return (
    <Box
      sx={{
        background: (theme) => theme.custom.gradient.cardSecondary,

        height: "100%",
        minHeight: "100%",

        padding: "32px",
        position: "relative",

        textAlign: "left",
        display: "flex",
        justifyContent: "center",

        borderRadius: "12px",
      }}
    >
      <Box sx={{ marginBottom: "18px" }}>
        <CardMedia
          alt="Imagem de um foguete"
          component="img"
          image={toAbsoluteUrl("/media/stars 3d.svg")}
          sx={{
            width: { sx: "100px", sm: "100px" },
            height: { sx: "80px", sm: "55px" },
          }}
        />
        <Typography
          sx={{
            fontSize: { sx: "16px", sm: "20px" },
            fontWeight: "500",
            mb: "12px",
            color: "white",
          }}
        >
          Assine seu plano Premium e continue gerenciando seu negócio
        </Typography>

        <Typography
          sx={{ fontSize: { sx: "12px", sm: "16px" }, color: "white" }}
        >
          Cancele quando quiser igual Netflix.
        </Typography>
      </Box>

      <CardMedia
        alt="Imagem de um foguete"
        component="img"
        image={toAbsoluteUrl("/media/Design sem nome (2).svg")}
        sx={{
          bottom: 0,
          right: 0,
          width: { sx: "120px", sm: "200px", lg: "230px" },
          height: { sx: "120px", sm: "200px", lg: "230px" },
          position: "absolute",
        }}
      />
    </Box>
  );
};
