import { Avatar, Box } from "@mui/material";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";
import { UseFormUploadAvatar } from "./Hooks/UseFormUploadAvatar";
import { DropZoneCore } from "src/Pages/components/DropZoneCore/DropZoneCore";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";

export const UploadAvatar = () => {
  const { handleUpdateAvatar, loading } = UseFormUploadAvatar();
  const { userPerfil } = useAuthContext();

  return (
    <DropZoneCore
      onFilesChange={handleUpdateAvatar}
      isLoading={loading}
      accept={{
        "image/jpeg": [],
        "image/png": [],
        "image/JPG": [],
        "image/WEBP": [],
      }}
      sxStyle={{
        padding: 1,
        borderRadius: "50%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: 110,
        height: 110,
      }}
    >
      <Box
        sx={{
          border: "1px dashed #b4b4b4c2",
          padding: 1,
          borderRadius: "50%",
          "&:hover": {
            borderColor: "#b2b2b2", // Cor levemente mais escura no hover
            boxShadow: "0px 4px 8px rgba(125, 125, 125, 0.205)", // Sombra sutil
          },
        }}
      >
        <Avatar
          alt="Image user"
          src={
            userPerfil?.avatar?.url ||
            toAbsoluteUrl("/media/avatars/Avatar.webp")
          }
          sx={{
            width: 110,
            height: 110,
            background: "inherit",
          }}
        />
      </Box>
    </DropZoneCore>
  );
};
