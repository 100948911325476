import React, { createRef, useEffect } from "react";
import { useResetInputs } from "src/Contexts";
import { ModalCore } from "src/Pages/components";

import { UseFormCampaign } from "./Hooks/UseFormCampaign";
import { FormContrato } from "./DadosCampaign";
import { FormDados } from "./FormDados";

interface IModalCampaign {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalCampaign = ({ openModal, setOpenModal }: IModalCampaign) => {
  const { resetInputs } = useResetInputs();

  const { handleSubmit, loading: loadingSubmit } = UseFormCampaign({
    setOpenModal,
  });

  useEffect(() => {
    if (!openModal) resetInputs();
  }, [openModal]);

  const inputRef = createRef<HTMLInputElement>();
  const handleFocusedID = () => inputRef.current?.focus();

  useEffect(() => {
    handleFocusedID();
  }, []);

  return (
    <ModalCore
      open={openModal}
      setOpen={setOpenModal}
      loadingSubmit={loadingSubmit}
      sxStyle={{ width: { xl: "1000px", md: "600", xs: "90%" } }}
      title="Campanha"
      titleSubmit="Salvar"
      onChange={handleSubmit}
    >
      <FormContrato inputRef={inputRef} />

      <FormDados />
    </ModalCore>
  );
};
