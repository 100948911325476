import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import moment from "moment";
import { Typography } from "@mui/material";

import Box from "@mui/material/Box";
import { ButtonCore } from "../ButtonCore/ButtonCore";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { UrlConfig } from "src/shared/Utils/paths";
import { LinkCore } from "../LinkCore/LinkCore";

export const HeaderIsPlanTest = () => {
  const { userPerfil } = useAuthContext();
  const { delayedOpen } = useLayoutMainContext();

  if (!userPerfil) return null;

  const isViewModal =
    userPerfil?.subscription?.isActive === false ||
    userPerfil?.subscription === null;

  function calculateRemainingDays() {
    const today = moment();
    const endOfTrial = moment(userPerfil?.createdAt).add(7, "days"); // Adicionando 7 dias
    const remainingDays = endOfTrial.diff(today, "days"); // Diferença em dias
    return remainingDays > 0 ? remainingDays : 0; // Retorna 0 se o teste já expirou
  }

  const isMoreOne = calculateRemainingDays() > 1;

  return (
    <>
      {isViewModal && (
        <Box
          style={{
            opacity: delayedOpen ? 1 : 0,
            transition: "opacity 180ms ease-in-out",
          }}
        >
          <Box
            sx={{
              position: "relative",
              boxShadow: 5,
              border: (theme) => `2px solid ${theme.palette.primary.main}`,
              width: "80%",
              margin: "0 auto",
              padding: "14px",
              borderRadius: 4,
              paddingBottom: "26px",
              mb: 5,
              textAlign: "center",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography mb={2} mr={1} sx={{ fontWeight: 500 }}>
                Plano Mensal
              </Typography>
              <img
                alt="logo da empresa "
                src={toAbsoluteUrl("/media/Posts Correctors.png")}
                style={{ height: "28px", width: "24px" }}
              />
            </Box>
            <Typography
              sx={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                fontSize: { xs: "12px", sm: "13px" },
              }}
            >
              {isMoreOne
                ? `Você não possui um plano ativo! Aproveite os ${calculateRemainingDays()} dias de período de avaliação.`
                : `Você não possui um plano ativo! Aproveite o ÚLTIMO DIA do período de avaliação.`}
            </Typography>

            <LinkCore to={UrlConfig.plans.url}>
              <ButtonCore
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  position: "absolute",
                  left: "50%",
                  bottom: "-20px",
                  transform: "translateX(-50%)",
                  borderRadius: "20px",
                  padding: "8px 16px",
                  letterSpacing: ".8px",
                  fontSize: { xs: "12px", sm: "12px" },
                  whiteSpace: "nowrap",
                  background:
                    "radial-gradient(circle, rgba(130,52,233,1) 44%, rgba(91,52,233,1) 100%)",
                }}
                title="Assinar R$39,90"
              />
            </LinkCore>
          </Box>
        </Box>
      )}
    </>
  );
};
