import { ChangeEvent, Children } from "react";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import { AppTextField } from "src/Pages/components";
import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { handleGenerateUuid } from "src/shared/Utils/handleGenerateUuid";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AddIcon } from "src/Pages/components/Icons/Icons";
import { DividerCore } from "src/Pages/components/DividerCore/DividerCore";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css"; // Tema escuro embutido

import ReactQuill from "react-quill";

import "./react-quill.css";
import { CardCore } from "src/Pages/components/CardCore/CardCore";

export const FormDados = () => {
  const { valuesInputsCampaign, setValuesInputsCampaign } =
    useContextCampaign();
  console.log(valuesInputsCampaign);
  const theme = useTheme();

  const handleAddFlows = () => {
    setValuesInputsCampaign((prevValues) => ({
      ...prevValues,
      flows: [
        ...prevValues.flows,
        {
          id: handleGenerateUuid(),
          title: "",
          date: "",
          time: "",
          message: [""],
        },
      ],
    }));
  };

  // Adiciona um novo campo de mensagem dentro de um flows
  const handleAddMessage = (index: number) => {
    setValuesInputsCampaign((prevValues) => {
      const updatedFlows = prevValues.flows.map((flows, i) =>
        i === index ? { ...flows, message: [...flows.message, ""] } : flows
      );

      return { ...prevValues, flows: updatedFlows };
    });
  };

  // Atualiza um campo dentro de um flows (título, data, horário ou mensagem específica)
  const handleDependenteChange = (
    eventOrValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,
    index: number,
    option: "title" | "date" | "time" | "message",
    messageIndex?: number
  ) => {
    setValuesInputsCampaign((prevValues) => {
      const fieldMap: Record<"title" | "date" | "time" | "message", string> = {
        title: "title",
        date: "date",
        time: "time",
        message: "message",
      };

      const fieldToUpdate = fieldMap[option];
      if (!fieldToUpdate) return prevValues;

      const value =
        typeof eventOrValue === "string"
          ? eventOrValue
          : eventOrValue.target.value;

      const updatedFlows = prevValues.flows.map((flows, i) => {
        if (i !== index) return flows;

        if (option === "message" && typeof messageIndex === "number") {
          const updatedMessage = [...flows.message];
          updatedMessage[messageIndex] = value;
          return { ...flows, message: updatedMessage };
        }

        return { ...flows, [fieldToUpdate]: value };
      });

      return { ...prevValues, flows: updatedFlows };
    });
  };

  const handleRemoveMessage = (flowsIndex: number, messageIndex: number) => {
    setValuesInputsCampaign((prevValues) => {
      const updatedFlows = prevValues.flows.map((flows, index) =>
        index === flowsIndex
          ? {
              ...flows,
              message: flows.message.filter((_, i) => i !== messageIndex),
            }
          : flows
      );

      return { ...prevValues, flows: updatedFlows };
    });
  };

  const removeFlows = (id: string) => {
    setValuesInputsCampaign((prevValues) => {
      if (prevValues.flows.length <= 1) return prevValues; // Evita remover se houver apenas um flows

      return {
        ...prevValues,
        flows: prevValues.flows.filter((flows) => flows.id !== id),
      };
    });
  };

  return (
    <>
      <Box sx={{ marginBottom: "12px" }}>
        {Children.toArray(
          valuesInputsCampaign.flows.map((flows, index) => {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <DividerCore
                    title={`Processo: ${index + 1}`}
                    sxStyle={{ width: "100%", marginRight: "10px" }}
                  />

                  <IconButton
                    onClick={() => removeFlows(flows.id)}
                    sx={{ width: "50px", height: "50px" }}
                  >
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </Box>

                <Grid container spacing={2}>
                  <Grid container item spacing={2} xs={12} sm={6}>
                    <Grid item xs={12} lg={12}>
                      <AppTextField
                        label="Nome do processo *"
                        fullWidth
                        value={flows.title || ""}
                        onChange={(e) =>
                          handleDependenteChange(e, index, "title")
                        }
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <AppTextField
                        label="Data do disparo *"
                        fullWidth
                        type="date"
                        value={flows.date}
                        onChange={(e) =>
                          handleDependenteChange(e, index, "date")
                        }
                      />
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <AppTextField
                        label="Horário do disparo *"
                        fullWidth
                        type="time"
                        value={flows.time}
                        onChange={(e) =>
                          handleDependenteChange(e, index, "time")
                        }
                      />
                    </Grid>

                    {/* Renderizando várias mensagens */}
                    <Grid container item spacing={2}>
                      {flows.message.map((message, messageIndex) => (
                        <Grid item xs={12} lg={12} key={messageIndex}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <div
                              className="dark-editor"
                              style={{ flex: 1, marginRight: "12px" }}
                            >
                              <ReactQuill
                                className={
                                  theme.palette.mode === "dark"
                                    ? "quill-dark"
                                    : "quill-light"
                                }
                                modules={{ toolbar: false }}
                                value={message || ""}
                                onChange={(e: never) =>
                                  handleDependenteChange(
                                    e,
                                    index,
                                    "message",
                                    messageIndex
                                  )
                                }
                              />
                            </div>
                            <IconButton
                              onClick={() =>
                                handleRemoveMessage(index, messageIndex)
                              }
                            >
                              <DeleteForeverIcon
                                fontSize="small"
                                color="error"
                              />
                            </IconButton>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                    {/* Botão para adicionar mais mensagens ao flows */}
                    <Grid item xs={12}>
                      <ButtonCore
                        fullWidth
                        title="Adicionar Mensagem nesse processo"
                        onClick={() => handleAddMessage(index)}
                        variant="outlined"
                        color="primary"
                      />
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6}>
                    <CardCore
                      sxStyle={{
                        background: (theme) =>
                          theme.palette.mode === "dark" ? "#292929" : "#EFEAE2",
                        padding: "16px",
                        borderRadius: "8px",
                        color: "white",
                        minHeight: "100%",
                        minWidth: "100%",
                        margin: 0,
                      }}
                    >
                      <Grid container item xs={12} spacing={2}>
                        {flows.message.map((message, messageIndex) => (
                          <Grid item xs={12}>
                            <CardCore
                              key={messageIndex}
                              sxStyle={{
                                background: (theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#086552"
                                    : "#D9FDD3",
                                padding: "8px",
                                borderRadius: "8px",
                                minHeight: "40px",
                                color: (theme) =>
                                  theme.palette.mode === "dark"
                                    ? "white"
                                    : "black",
                                margin: 0,
                              }}
                            >
                              <div
                                className="prose max-w-none"
                                style={{
                                  wordBreak: "break-word",
                                  whiteSpace: "pre-wrap",
                                  overflowWrap: "break-word",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: message || "",
                                }}
                              />
                            </CardCore>
                          </Grid>
                        ))}
                      </Grid>
                    </CardCore>
                  </Grid>
                </Grid>
              </>
            );
          })
        )}
      </Box>

      <ButtonCore
        endIcon={<AddIcon sx={{ mr: 1 }} />}
        fullWidth
        title="Adicionar novo processo"
        onClick={handleAddFlows}
        variant="outlined"
        color="warning"
      />
    </>
  );
};
