import { Box, Grid } from "@mui/material";

import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";

import { CardHeaderCore } from "src/Pages/components/CardCore/Components/CardHeaderCore";
import { ModalCampaignNewInstance } from "../FormCreateInstances";
import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { Children, useEffect, useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useHttpTableInstancesWhats } from "./Hooks/useHttpTableInstancesWhats";
import { CardInstancesWhats } from "./Components/CardInstancesWhats/CardInstancesWhats";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { AddIcon } from "src/Pages/components/Icons/Icons";
import { InfoModalInstancesWhats } from "./Components/InfoModalInstancesWhats";
import { ModalInfoCore } from "src/Pages/components/ModalInfo/ModalInfo";

export const TableInstancesWhats = () => {
  const { listInstancesWhats } = useContextCampaign();

  const { itensPerPage, currentPage, nameSearch, attTable } =
    useConfigPageContext();

  const [openModal, setOpenModal] = useState(false);

  const { handleGet } = useHttpTableInstancesWhats();

  useEffect(() => {
    handleGet();
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  return (
    <>
      <ModalCampaignNewInstance
        key={1}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <CardHeaderCore
        buttonAdd={[
          <Box sx={{ display: "flex" }}>
            <ModalInfoCore title=" Tutorial: Conexão do WhatsApp">
              <InfoModalInstancesWhats />
            </ModalInfoCore>
            <ButtonCore
              sx={{ ml: 2 }}
              startIcon={<AddIcon />}
              title="Novo WhatsApp"
              onClick={() => setOpenModal(true)}
            />
          </Box>,
        ]}
        icon={<AppSettingsAltIcon />}
        elevation={1}
        sxStyle={{ margin: "0 0 16px 0", padding: "12px", boxShadow: "" }}
        title={`Números de WhatsApp`}
      />

      <Grid container spacing={2}>
        {Children.toArray(
          listInstancesWhats.map((item) => <CardInstancesWhats data={item} />)
        )}
      </Grid>
    </>
  );
};
