import { ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { ICreateInstancesWhats } from "src/Contexts/Campaign/CampaignContextTypes";

export const FormCreateInstances = () => {
  const { valuesInputsInstancesWhats, setValuesInputsInstancesWhats } =
    useContextCampaign();

  const handleChange =
    (prop: keyof ICreateInstancesWhats) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsInstancesWhats((prevValue) => ({
        ...prevValue,
        [prop]: event.target.value,
      }));
    };

  return (
    <Grid container item spacing={3}>
      <Grid item xs={12}>
        <AppTextField
          label="Nome da conexão"
          fullWidth
          placeholder="Chip para campanha de leads quentes"
          value={valuesInputsInstancesWhats?.title}
          onChange={handleChange("title")}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          label="Descrição"
          fullWidth
          placeholder="Obs: Chip para teste"
          value={valuesInputsInstancesWhats?.description}
          onChange={handleChange("description")}
        />
      </Grid>
    </Grid>
  );
};
