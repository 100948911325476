import { formatRemove } from "@format-string/core";
import { useState } from "react";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IUseFormUpdateWhats {
  setActiveStep: (value: React.SetStateAction<number>) => void;
}

export const UseFormUpdateWhats = ({ setActiveStep }: IUseFormUpdateWhats) => {
  const { setUserPerfil, userPerfil } = useAuthContext();
  const { valueWhatsUpdate } = useLayoutMainContext();
  const { handleGetAlert } = useLayoutMainContext();

  const [loading, setLoading] = useState(false);

  const AllDatas = {
    phone1: formatRemove(valueWhatsUpdate),
    email: userPerfil?.email,
    name: userPerfil?.name,
  };

  const handleUpdate = async () => {
    if (!AllDatas.phone1)
      return handleGetAlert({ message: `Digite um WhatsApp !` });

    setLoading(true);

    return api
      .patch("/registers", AllDatas)
      .then((res) => {
        handleGetAlert({ message: res.data.message });
        setActiveStep((setActiveStep) => setActiveStep + 1);

        if (userPerfil) {
          setUserPerfil(() => ({
            ...userPerfil,
            phone1: AllDatas.phone1,
          }));
        }

        api
          .patch("/users/firstConfig", {
            firstConfig: {
              ...userPerfil?.firstConfig,
              introduction: true,
            },
          })
          .then(() => {
            if (userPerfil) {
              setUserPerfil(() => ({
                ...userPerfil,
                firstConfig: {
                  ...userPerfil?.firstConfig,
                  introduction: true,
                },
              }));
            }
          });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      )
      .finally(() => setLoading(false));
  };

  return { handleSubmit: handleUpdate, loading };
};
