import { createContext, useContext, useState, FC, ReactNode } from "react";

import {
  ICreateInstancesWhats,
  IListInstancesWhats,
  ICreateGroupLeads,
  IContextCampaign,
  ICreateCampaign,
  IListGroupLeads,
  IListCampaign,
} from "./CampaignContextTypes";
import {
  valuesDefaultInputsInstancesWhats,
  valuesDefaultInputsGroupLeads,
  valuesDefaultInputsCampaign,
} from "./ValuesDefaultInputsCampaign/valuesDefaultInputsInstancesWhats";

const CampaignContext = createContext({} as IContextCampaign);

export const CampaignContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listInstancesWhats, setListInstancesWhats] = useState(
    [] as IListInstancesWhats[]
  );

  const [listGroupLeads, setListGroupLeads] = useState([] as IListGroupLeads[]);
  const [listCampaign, setListCampaign] = useState([] as IListCampaign[]);

  const [valuesInputsInstancesWhats, setValuesInputsInstancesWhats] =
    useState<ICreateInstancesWhats>(valuesDefaultInputsInstancesWhats);

  const [valuesInputsCampaign, setValuesInputsCampaign] =
    useState<ICreateCampaign>(valuesDefaultInputsCampaign);

  const [valuesInputsGroupLeads, setValuesInputsGroupLeads] =
    useState<ICreateGroupLeads>(valuesDefaultInputsGroupLeads);

  return (
    <CampaignContext.Provider
      value={{
        listCampaign,
        listGroupLeads,
        setListCampaign,
        setListGroupLeads,
        listInstancesWhats,
        valuesInputsCampaign,
        setListInstancesWhats,
        valuesInputsGroupLeads,
        setValuesInputsCampaign,
        setValuesInputsGroupLeads,
        valuesInputsInstancesWhats,
        setValuesInputsInstancesWhats,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export const useContextCampaign = (): IContextCampaign =>
  useContext(CampaignContext);
