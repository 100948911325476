import { Box, Typography } from "@mui/material";

export const InfoModalInstancesWhats = () => {
  return (
    <Box>
      <Typography paragraph>
        A tela de Conexão do WhatsApp permite gerenciar múltiplos números
        conectados para disparo de mensagens em massa. É necessário escanear o
        QR Code para estabelecer a conexão.
      </Typography>

      <Typography paragraph>
        Obs: Recomendamos renomear a conexão do &#34;dispositivos
        conectados&#34; do seu WhatsApp para &#34;Correctors&#34; assim evita de
        remover conexão por engano.
      </Typography>

      <Typography variant="h6">Gerenciando Números Conectados</Typography>
      <Typography component="ol" mb={2}>
        <li>
          Clique em <strong>Adicionar Número</strong>.
        </li>
        <li>
          Escaneie o QR Code exibido na tela com o aplicativo do WhatsApp.
        </li>
        <li>
          Após a conexão, o status do número será atualizado para{" "}
          <strong>Conectado</strong>.
        </li>
      </Typography>

      <Typography variant="h6">Desconectando e Excluindo Números</Typography>
      <Typography component="ol" mb={2}>
        <li>Selecione o número desejado.</li>
        <li>
          Clique em <strong>Desconectar</strong> para remover temporariamente o
          acesso.
        </li>
        <li>
          Clique em <strong>Excluir</strong> para remover permanentemente o
          número.
        </li>
      </Typography>

      <Typography variant="h6">Boas Práticas para Evitar Bloqueios</Typography>
      <Typography component="ul" mb={2}>
        <li>
          <strong>Evite disparos em massa:</strong> Limite o envio a 30
          mensagens por dia por número conectado.
        </li>
        <li>
          <strong>Aqueça o número:</strong> Use o chip por pelo menos 15 dias
          antes de iniciar os disparos pela plataforma.
        </li>
        <li>
          <strong>Utilize um chip exclusivo:</strong> Recomenda-se usar um
          número dedicado apenas para essa finalidade.
        </li>
      </Typography>

      <Typography paragraph>
        Com essas práticas, você reduz o risco de bloqueios e garante maior
        estabilidade nas campanhas de mensagens.
      </Typography>
    </Box>
  );
};
