import { useEffect, useState } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

interface IGetOperatorsAll {
  id: number;
  title: string;
  description: string;
  number: string;
  urlImage: string;
  status: "open" | "close" | "connection" | "created";
}

export const HttpGetInstancesWhatsAll = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const [data, setData] = useState<IGetOperatorsAll[]>([]);

  const [loading, setLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const handleGet = () => {
      setLoading(true);

      api
        .get(`/instancesWhats/all`)
        .then(({ data }) => {
          setData(data.data);
        })
        .catch((error) =>
          handleGetAlert({ message: error.response.data.message })
        )
        .finally(() => setLoading(false));
    };

    handleGet();
  }, [refresh]);

  return { loading, data, setRefresh };
};
