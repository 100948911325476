import { FC } from "react";

import { AuthContextProvider } from "./Contexts/AuthContext/authContext";
import { ComissoesContextProvider } from "./Contexts/comissoes/ComissoesContext";
import { CompanyProviderContext } from "./Contexts/Company/CompanyContext";
import { ConfigPageProviderContext } from "./Contexts/configPagesContext/configPagesContext";
import { ContextProfileProviderContext } from "./Contexts/ContextProfile/ContextProfile";
import { ContextDashboardBrokerProviderContext } from "./Contexts/Dashboard/ContextDashboardBroker";
import { ContratoContextProvider } from "./Contexts/Lancamentos/Contrato/ContratosContext";
import { ReportsProductionContextProvider } from "./Contexts/ReportProduction/ReportsProductionContextContext";
import { AutoCompletedAllContextProvider } from "./Contexts/Utils/UseAutoCompletedAll";
import { ViewsContext } from "./Contexts/Views/contextViews";
import { combineComponents } from "./shared/Utils/ProviderContext/ProviderContext";
import { LeadsContextProvider } from "./Contexts/Leads/LeadsContext";
import { DriveContextProvider } from "./Contexts/Drive/DriveContext";
import { CampaignContextProvider } from "./Contexts/Campaign/ContextCampaign";

const providers = [
  AuthContextProvider,
  ViewsContext,
  AutoCompletedAllContextProvider,
  ComissoesContextProvider,
  ContratoContextProvider,
  LeadsContextProvider,
  ReportsProductionContextProvider,
  ConfigPageProviderContext,
  CompanyProviderContext,
  CampaignContextProvider,
  ContextProfileProviderContext,
  DriveContextProvider,
  ContextDashboardBrokerProviderContext,
];

export const AppContextProvider = combineComponents(providers as FC[]);
