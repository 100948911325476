import { FC, createContext, useContext, useState, ReactNode } from "react";

import { IAutoCompleted } from "../types/ProdutoresContext.Types";

import { IAutoCompletedAllContextProvider } from "./types";

const UtilsContext = createContext({} as IAutoCompletedAllContextProvider);

const valueDefaultInput = { label: "", id: "" };

export const AutoCompletedAllContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [valueAutoCompAdministradora, setValueAutoCompAdministradora] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompModalidade, setValueAutoCompModalidade] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompOperadora, setValueAutoCompOperadora] =
    useState<IAutoCompleted>(valueDefaultInput);

  const [valueAutoCompInstancesWhats, setValueAutoCompInstancesWhats] =
    useState<IAutoCompleted>(valueDefaultInput);

  return (
    <UtilsContext.Provider
      value={{
        valueAutoCompOperadora,
        valueAutoCompModalidade,
        setValueAutoCompOperadora,
        setValueAutoCompModalidade,
        valueAutoCompInstancesWhats,
        valueAutoCompAdministradora,
        setValueAutoCompAdministradora,
        setValueAutoCompInstancesWhats,
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
};

export const UseAutoCompletedAll = (): IAutoCompletedAllContextProvider =>
  useContext(UtilsContext);
