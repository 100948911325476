import { CircularProgress, Typography } from "@mui/material";

export const FormCancelContract = ({
  qrCode,
  loading,
}: {
  qrCode: string;
  loading: boolean;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography sx={{ marginBottom: "12px", fontWeight: 500 }}>
            Aponte seu celular para esta tela para escanear o QR code.
          </Typography>

          <img src={qrCode} alt="QR Code" style={{ width: 200, height: 200 }} />
        </>
      )}
    </div>
  );
};
