import { Children, useEffect, useState } from "react";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";

import {
  ModalDeleteCore,
  TableRowCore,
  TableCore,
  TdCore,
} from "src/Pages/components";
import {
  AddIcon,
  EditIcon,
  DeleteForeverIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";

import { UseGetByIdCampaign } from "./Hooks/UseGetByIdContratos";
import { useHttpTableCampaign } from "./Hooks/useHttpTableCampaign";
import { THeadCampaign } from "./THead";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { ModalCampaign } from "../Form";
import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { FormControlLabel } from "@mui/material";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";
import { cpfOurCnpj } from "src/shared/Utils/MaskCpfCnpj";
import { IOSSwitch } from "./Components/ToggleStart";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { InfoModalCampaign } from "../Components/InfoModalCampaign";

export const TableCampaign = () => {
  const { userPerfil } = useAuthContext();
  const { resetInputs } = useResetInputs();
  const { handleGet } = useHttpTableCampaign();
  const { listCampaign } = useContextCampaign();
  const { handleGetById } = UseGetByIdCampaign();

  const [openModalContracts, setOpenModalContracts] = useState(false);

  const {
    setNameSearch,
    itensPerPage,
    currentPage,
    nameSearch,
    attTable,
    setId,
  } = useConfigPageContext();

  const handleGetIdToEdit = (id: string) => {
    resetInputs();
    setId(id);
    setOpenModalContracts(true);
    handleGetById(id);
  };

  useEffect(() => {
    handleGet();
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/campaign" });

  return (
    <TableCore
      Modals={
        <>
          <ModalCampaign
            openModal={openModalContracts}
            setOpenModal={setOpenModalContracts}
          />

          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja campanha ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={handleDelete}
          />
        </>
      }
      THead={<THeadCampaign />}
      modalInfo={{
        children: <InfoModalCampaign />,
        title: "Tutorial: Campanhas",
      }}
      qtdList={listCampaign.length}
      setNameSearch={setNameSearch}
      cardAdd={{
        buttons: [
          <ButtonCore
            disabled={
              ![
                "richard_madrigar99@hotmail.com",
                "dra.evelynbatista@gmail.com",
              ].includes(userPerfil?.email || "")
            }
            startIcon={<AddIcon />}
            title="Criar campanha"
            size="small"
            onClick={() => setOpenModalContracts(true)}
          />,
        ],
      }}
    >
      {Children.toArray(
        listCampaign.map((item) => {
          return (
            <TableRowCore sxStyle={{ height: "80px" }}>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      // {
                      //   icon: <CurrencyExchangeIcon fontSize="small" />,
                      //   title: "Ver Contrato",
                      //   background: "success",
                      //   onClick: () => {
                      //     setDataGetByIdContrato(item);
                      //     navigate(`/Lancamentos/contrato/${item.id}`);
                      //   },
                      // },

                      {
                        icon: <EditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        onClick: () => {
                          setId(String(item.id));
                          handleGetIdToEdit(String(item.id));
                        },
                      },
                      {
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.id);
                          setOpenModalDelete(true);
                        },
                        icon: <DeleteForeverIcon fontSize="small" />,
                      },
                    ]}
                  />
                }
              />

              <TdCore
                textAlign="center"
                values={
                  <FormControlLabel
                    control={<IOSSwitch sx={{ ml: 4 }} defaultChecked />}
                    label=""
                  />
                }
                subTitle={"Em andamento"}
              />

              <TdCore
                values={item.title}
                textAlign="left"
                subTitle={item.description}
              />
              <TdCore
                values={item.groupLeads.title}
                textAlign="left"
                subTitle={`Total de Leads: ${item.groupLeads._count.GroupManyLeads}`}
              />

              <TdCore
                textAlign="left"
                values={item.instancesWhats.title}
                subTitle={cpfOurCnpj({
                  text: item.instancesWhats.number
                    ? item.instancesWhats.number
                    : "",
                  type: "phone",
                })}
              />

              <TdCore values={`${item.flowCurrency} / ${item.flowTotal}`} />

              <TdCore
                textAlign="center"
                values={`${FormatDateBR(item.flows[0].date)} as ${
                  item.flows[0].hour
                }`}
                sxStyle={{
                  fontWeight: "600",
                  color: (theme) => theme.custom.color.money,
                  borderRadius: "12px",
                  border: "1px solid gray",
                }}
              />

              <TdCore values={FormatDateBR(item.createdAt)} />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
