import React, { useState, useCallback, ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import { handleGenerateUuid } from "src/shared/Utils/handleGenerateUuid";

export interface IListPDF {
  data: number;
  status: string;
  name: string;
  previewUrl: string;
  pdf: ArrayBuffer;
  size: number;
  type: string;
  id: string;
}

interface UploadFilesProps {
  accept?: { [key: string]: string[] };
  onFilesChange?: (files: IListPDF[]) => void;
  children?: ReactNode;
  isLoading?: boolean;
  sxStyle?: SxProps<Theme>;
  compIsLoading?: ReactNode;
}

export const DropZoneCore: React.FC<UploadFilesProps> = ({
  accept = { "image/jpeg": [], "image/png": [] },
  onFilesChange,
  children,
  isLoading,
  compIsLoading,
  sxStyle,
}) => {
  const [listFiles, setListFiles] = useState<IListPDF[]>([]);

  const handleUploadFile = useCallback(
    async (files: File[]) => {
      const processedFiles: IListPDF[] = await Promise.all(
        files.map(async (file) => {
          const arrayBuffer = await file.arrayBuffer();
          const fileUrl = URL.createObjectURL(file);

          return {
            id: handleGenerateUuid(),
            name: file.name,
            data: file.lastModified,
            size: file.size,
            status: "Na fila",
            pdf: arrayBuffer,
            type: file.type,
            previewUrl: fileUrl,
          };
        })
      );
      const updatedList = [...processedFiles];

      setListFiles(updatedList);
      if (onFilesChange) onFilesChange(updatedList);
    },
    [listFiles, onFilesChange]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleUploadFile(acceptedFiles);
    },
    [handleUploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    disabled: isLoading,
  });

  return (
    <Box
      component="section"
      {...getRootProps()}
      sx={{
        cursor: isLoading ? "default" : "pointer",
        transition: "background 0.3s ease",
        ...sxStyle,
      }}
    >
      <input {...getInputProps()} />
      {isLoading ? compIsLoading || <CircularProgress /> : children}
    </Box>
  );
};
