import React, { useEffect } from "react";
import { FormCore, ModalCore } from "src/Pages/components";

import { FormCommissions } from "./FormCommissions";
import { UseFormCommissions } from "./hook/UseFormCommissions";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { valuesDefaultInputsCommissions } from "src/Contexts/comissoes/ValuesDefaultInputsComissoes/ValuesDefaultInputsComissoes";
import { Box, CircularProgress, DialogActions, Divider } from "@mui/material";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { SendIcon } from "src/Pages/components/Icons/Icons";
import { useStyles } from "src/Pages/components/ModalFilterCore/Index";

interface IModalCommissions {
  openModal?: boolean;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isModal?: boolean;
  isCommissionInternal?: boolean;
  titleSubmit?: string;
  handleSummitUpdate?: () => void;

  setIdCommission?: React.Dispatch<React.SetStateAction<string>>;
  idCommission: string;
}

export const ModalCommissions = ({
  isModal,
  openModal,
  titleSubmit,
  setOpenModal,
  handleSummitUpdate,
  idCommission,
  setIdCommission,
}: IModalCommissions) => {
  const { setValuesInputsCommissions } = useContextComissoes();

  const { handleSubmit, loading: loadingSubmit } = UseFormCommissions({
    setOpenModal,
    handleSummitUpdate,
    idCommission,
  });

  const classes = useStyles();

  useEffect(() => {
    if (!openModal) {
      setValuesInputsCommissions(valuesDefaultInputsCommissions);
      setIdCommission && setIdCommission("");
    }
  }, [openModal]);

  return (
    <>
      {isModal && setOpenModal ? (
        <ModalCore
          open={openModal || false}
          setOpen={setOpenModal}
          loadingSubmit={loadingSubmit}
          sxStyle={{ width: "600px" }}
          title="Comissões"
          titleSubmit={titleSubmit || "Salvar"}
          onChange={handleSubmit}
        >
          <FormCommissions />
        </ModalCore>
      ) : (
        <FormCore sxStyle={{ margin: 0 }}>
          <Box
            className={classes.customScrollbar}
            sx={{ overflow: "auto", padding: "8px" }}
          >
            <FormCommissions />
          </Box>

          <DialogActions>
            <>
              <Divider sx={{ marginTop: 2, opacity: 1 }} />

              <ButtonCore
                disabled={!!loadingSubmit}
                endIcon={
                  !loadingSubmit ? (
                    <SendIcon sx={{ fontSize: 4 }} />
                  ) : (
                    <CircularProgress
                      size={15}
                      sx={{ mr: 1, color: "black" }}
                    />
                  )
                }
                title={titleSubmit || "Salvar"}
                type="submit"
                onClick={handleSubmit}
              />
            </>
          </DialogActions>
        </FormCore>
      )}
    </>
  );
};
