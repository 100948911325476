import { formatString } from "@format-string/core";
import {
  Box,
  Grid,
  Divider,
  Typography,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useEffect } from "react";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { AppTextField } from "src/Pages/components/AppFormComponents/AppTextField";
import { FormCore } from "src/Pages/components/AppFormComponents/FormCore";
import { CardCore } from "src/Pages/components/CardCore/CardCore";
import { WhatsAppIcon } from "src/Pages/components/Icons/Icons";
import { useIsValidNumberWhats } from "src/shared/Hooks/useIsValidNumberWhats";
import { apenasNumeros } from "src/shared/Utils/FormatMoney";
import { UseFormUpdateWhats } from "./Hooks/UseFormUpdateWhats";
import { useAuthContext } from "src/Contexts/AuthContext/authContext";
import { toAbsoluteUrl } from "src/shared/setup/toAbsoluteUrl";

interface IFormConfirmationsData {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export const FormConfirmationsData = ({
  setActiveStep,
}: IFormConfirmationsData) => {
  const { userPerfil } = useAuthContext();
  const { valueWhatsUpdate, setValueWhatsUpdate } = useLayoutMainContext();

  const { handleSubmit: handleUpdate, loading: loadingSubmit } =
    UseFormUpdateWhats({
      setActiveStep,
    });

  useEffect(() => {
    setValueWhatsUpdate(
      formatString({ value: userPerfil?.phone1, type: "phone" }) as string
    );
  }, [userPerfil?.phone1]);

  const {
    isWhatsValid,
    handle: handleIsValidWhats,
    isLoading: isLoadingValidationWhats,
  } = useIsValidNumberWhats();

  const isWhatsLength = apenasNumeros(valueWhatsUpdate)?.length === 11;
  const isNotWhatsValid = !isWhatsValid || !isWhatsLength;

  useEffect(() => {
    if (isWhatsLength) {
      handleIsValidWhats(valueWhatsUpdate);
    }
  }, [valueWhatsUpdate]);

  return (
    <Box>
      <CardCore sx={{ padding: 0, margin: "0.1rem" }}>
        <CardContent sx={{ padding: "12px" }}>
          <Typography sx={{ fontSize: "14px" }}>
            Modelo de Relatório semanal via WhatsApp
          </Typography>
        </CardContent>
        <Box
          component={"img"}
          src={toAbsoluteUrl("/media/WhatsExemple.png")}
          alt="Ícone"
          width="100%"
          height={350}
          sx={{ borderRadius: "12px" }}
        />
      </CardCore>

      <FormCore
        sxStyle={{ margin: "0" }}
        handleSubmit={handleUpdate}
        isNotCancel
        titleSubmit="Avançar"
        marginTopButtons="24px"
        loadingSubmit={loadingSubmit}
      >
        <Divider sx={{ margin: "20px 0" }} />

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AppTextField
                fullWidth
                label="Whats"
                placeholder="(11) 9-8070-1020"
                value={valueWhatsUpdate}
                sxStyle={{ mr: "8px" }}
                onChange={(e) => {
                  const formattedPhone = formatString({
                    value: e.target.value,
                    type: "phone",
                  }) as string;
                  setValueWhatsUpdate(formattedPhone);
                }}
              />
              {isLoadingValidationWhats ? (
                <CircularProgress color="inherit" size="1em" />
              ) : (
                <WhatsAppIcon
                  fontSize="small"
                  color={isNotWhatsValid ? "error" : "success"}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </FormCore>
    </Box>
  );
};
