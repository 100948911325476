import { ChangeEvent, useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import { useContextContrato } from "src/Contexts/Lancamentos/Contrato/ContratosContext";
import { ICreateContrato } from "src/Contexts/Lancamentos/Contrato/ContratosTypes";
import { AppTextField } from "src/Pages/components";

import { HttpGetOperatorsAll } from "src/shared/Hooks/Https/HttpGetOperatorsAll";
import { HttpGetModalitiesAll } from "src/shared/Hooks/Https/HttpGetModalitiesAll";
import { HttpGetAdministradorasAll } from "src/shared/Hooks/Https/HttpGetAdministratorsAll";
import { HttpGetCompaniesAll } from "src/shared/Hooks/Https/HttpGetCompaniesAll";
import { ModalCommissions } from "src/Pages/TelasSistemas/Commissions/GridCommissions/components/Form";
import {
  ApiResponseSEtDataGrid,
  CardPercent,
} from "../CardPercent/CardPercent";

interface IFormContrato {
  inputRef: React.RefObject<HTMLInputElement>;
}

export const FormContrato = ({ inputRef }: IFormContrato) => {
  const { valuesInputsContrato, setValuesInputsContrato } =
    useContextContrato();

  const [openModalGrid, setOpenModalGrid] = useState(false);

  const [dataGrid, setDataGrid] = useState<
    ApiResponseSEtDataGrid | undefined
  >();

  const { data: resultListOperators } = HttpGetOperatorsAll();
  const { data: resultListCompanies } = HttpGetCompaniesAll();
  const { data: resultListModalities } = HttpGetModalitiesAll();
  const { data: resultListAdministrators } = HttpGetAdministradorasAll();

  const commissionInstallments =
    dataGrid?.data?.commissionInstallments ??
    valuesInputsContrato.commissions?.commissionInstallments;

  const { companyCode, operatorCode, modalityCode } = valuesInputsContrato;

  const updatedResultListCompanies = [
    { code: 0, name: "Nenhum" },
    ...resultListAdministrators,
  ];

  const handleChange =
    (prop: keyof ICreateContrato) => (event: ChangeEvent<HTMLInputElement>) => {
      setValuesInputsContrato((eventPrev) => ({
        ...eventPrev,
        [prop]: event.target.value,
      }));
    };

  return (
    <>
      <ModalCommissions
        openModal={openModalGrid}
        setOpenModal={setOpenModalGrid}
        isModal={true}
        idCommission=""
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <AppTextField
            label="Proposta *"
            fullWidth
            placeholder="Código da proposta"
            inputRef={inputRef}
            value={valuesInputsContrato.proposalCode}
            onChange={handleChange("proposalCode")}
          />
        </Grid>
        <Grid item xs={6} md={3.5}>
          <AppTextField
            label="Data assinatura *"
            fullWidth
            type="date"
            value={valuesInputsContrato.subscriptionDate}
            onChange={handleChange("subscriptionDate")}
          />
        </Grid>
        <Grid item xs={6} md={3.5}>
          <AppTextField
            select
            id="tipo_contrato"
            label="Física | Jurídica"
            fullWidth
            value={valuesInputsContrato?.contractType}
            onChange={handleChange("contractType")}
          >
            <MenuItem value="PF">Física</MenuItem>
            <MenuItem value="PJ">Jurídica</MenuItem>
          </AppTextField>
        </Grid>

        <Grid item xs={6} md={2.5}>
          <AppTextField
            fullWidth
            select
            label="Operadora *"
            value={valuesInputsContrato.operatorCode}
            onChange={handleChange("operatorCode")}
          >
            {resultListOperators.map((option) => (
              <MenuItem key={option.code} value={String(option.code)}>
                {option.name}
              </MenuItem>
            ))}
          </AppTextField>
        </Grid>
        <Grid item xs={6} md={2.5}>
          <AppTextField
            fullWidth
            select
            label="Modalidade (produto) *"
            value={valuesInputsContrato.modalityCode}
            onChange={handleChange("modalityCode")}
          >
            {resultListModalities.map((option) => (
              <MenuItem key={option.code} value={String(option.code)}>
                {option.name}
              </MenuItem>
            ))}
          </AppTextField>
        </Grid>

        <Grid item xs={6} md={2.5}>
          <AppTextField
            fullWidth
            select
            label="Administradora"
            value={valuesInputsContrato.administratorCode}
            onChange={handleChange("administratorCode")}
          >
            {updatedResultListCompanies.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </AppTextField>
        </Grid>

        <Grid item xs={6} md={2.5}>
          <AppTextField
            fullWidth
            select
            label="Empresas"
            value={valuesInputsContrato.companyCode}
            onChange={handleChange("companyCode")}
          >
            {resultListCompanies.map((option) => (
              <MenuItem key={option.code} value={String(option.code)}>
                {option.name}
              </MenuItem>
            ))}
          </AppTextField>
        </Grid>
      </Grid>

      <CardPercent
        commissionInstallments={commissionInstallments}
        companyCode={companyCode}
        modalityCode={modalityCode}
        operatorCode={operatorCode}
        setOpenModalGrid={setOpenModalGrid}
        openModalGrid={openModalGrid}
        setDataGrid={setDataGrid}
      />
    </>
  );
};
