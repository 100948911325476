import { useContextCampaign } from "src/Contexts/Campaign/ContextCampaign";
import { useLayoutMainContext } from "src/Contexts/MainLayoutContext";
import { api } from "src/shared/setup/API/api";

export const UseGetByIdCampaign = () => {
  const { handleGetAlert } = useLayoutMainContext();

  const { setValuesInputsCampaign } = useContextCampaign();

  const handleGetById = async (id: string) => {
    await api
      .get<ICampaign>(`/campaign/${id}`)
      .then(({ data }) => {
        setValuesInputsCampaign({
          description: data.description,
          title: data.title,
          groupLeadsId: data.groupLeadsId,
          instancesWhatsId: data.instancesWhatsId,
          flows: data.flows.map((item) => {
            return {
              date: item.date,
              time: item.hour,
              id: item.id,
              message: item.message.map((item) => item.content),
              title: item.title,
            };
          }),
        });
      })
      .catch((error) =>
        handleGetAlert({ message: error.response.data.message })
      );
  };

  return { handleGetById };
};

interface Message {
  flow: number;
  content: string;
}

interface Flow {
  id: string;
  title: string;
  dateFull: string;
  date: string;
  hour: string;
  message: Message[];
  campaignId: string;
  updatedAt: string;
  createdAt: string;
}

interface ICampaign {
  id: string;
  title: string;
  description: string;
  idTenant: string;
  groupLeadsId: string;
  instancesWhatsId: string;
  updatedAt: string;
  createdAt: string;
  flowTotal: number;
  flowCurrency: number;
  flows: Flow[];
}
