import { Tab, Tabs } from "@mui/material";

import TabContext from "@mui/lab/TabContext";
import { useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import { TableCampaign } from "./CampaignConfig/Campaign/Table/TableCampaign";
import { TableInstancesWhats } from "./Instances/Components/Table/Index";

export const PageCampaign = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{ marginBottom: "16px" }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Campanha" value="1" />
        <Tab label="Números conectados" value="2" />
      </Tabs>

      <TabPanel sx={{ padding: 0 }} value="1">
        <TableCampaign />
      </TabPanel>

      <TabPanel sx={{ padding: 0 }} value="2">
        <TableInstancesWhats />
      </TabPanel>
    </TabContext>
  );
};
