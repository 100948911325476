import { createContext, useContext, ReactNode, useState, FC } from "react";
import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import {
  IListContratos,
  ICreateContrato,
  IContextContrato,
  IGetInstallments,
  ICancelarContrato,
  IUpdateInstallment,
  IListContractsFiles,
  IReceivedInstallment,
  ICreateNewInstallment,
  IValuesCancelContract,
  IValuesFilterContracts,
  IValuesFilterInstallments,
} from "./ContratosTypes";
import {
  valueDefaultInputReceivedInstallments,
  valueDefaultInputCancelContracts,
  valuesDefaultFilterInstallments,
  valuesDefaultFilterContrato,
  valueDefaultInputContratos,
} from "./valuesDefaultInputsContratos/ValueDefaultInputContratos";
import { IListPDF } from "src/Pages/components/DropZoneCore/DropZoneCore";

const ContratosContext = createContext({} as IContextContrato);

export const ContratoContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [listContratos, setListContratos] = useState([] as IListContratos[]);
  const [getInstallments, setGetInstallments] = useState<IGetInstallments>();

  const [dataGetByIdContrato, setDataGetByIdContrato] =
    useState<IListContratos>();

  const [valuesInputCancelarContrato, setValuesInputCancelarContrato] =
    useState<ICancelarContrato>({
      date: `${GetDateUSSemValue()}`,
      description: "",
    });

  const [valuesInputUpdateInstallment, setValuesInputUpdateInstallment] =
    useState<IUpdateInstallment>();

  const [valuesInputNewInstallments, setValuesInputNewInstallments] =
    useState<ICreateNewInstallment>();

  const [valuesInputsContrato, setValuesInputsContrato] =
    useState<ICreateContrato>({ ...valueDefaultInputContratos });
  const [valuesGet, setValuesGet] = useState<ICreateContrato>({
    ...valueDefaultInputContratos,
  });

  const [valuesFilterContrato, setValuesFilterContrato] =
    useState<IValuesFilterContracts>(valuesDefaultFilterContrato);

  const [valuesFilterInstallments, setValuesFilterInstallments] =
    useState<IValuesFilterInstallments>(valuesDefaultFilterInstallments);

  const [valuesReceivedInstallment, setValuesReceivedInstallment] =
    useState<IReceivedInstallment>(valueDefaultInputReceivedInstallments);

  const [valuesCancelContract, setValuesCancelContract] =
    useState<IValuesCancelContract>(valueDefaultInputCancelContracts);

  const [listContractsFiles, setListContractsFiles] = useState<
    IListContractsFiles[]
  >([]);

  const [uploadFiles, setUploadFiles] = useState<IListPDF[]>([]);

  const [activeCard, setActiveCard] = useState(0);

  return (
    <ContratosContext.Provider
      value={{
        setValuesInputUpdateInstallment,
        setValuesInputCancelarContrato,
        setValuesInputNewInstallments,
        valuesInputUpdateInstallment,
        setValuesReceivedInstallment,
        valuesInputCancelarContrato,
        setValuesFilterInstallments,
        valuesInputNewInstallments,
        valuesReceivedInstallment,
        valuesFilterInstallments,
        setValuesInputsContrato,
        setValuesCancelContract,
        setValuesFilterContrato,
        setDataGetByIdContrato,
        setListContractsFiles,
        valuesInputsContrato,
        valuesCancelContract,
        valuesFilterContrato,
        dataGetByIdContrato,
        listContractsFiles,
        setGetInstallments,
        setListContratos,
        getInstallments,
        setUploadFiles,
        listContratos,
        setActiveCard,
        setValuesGet,
        uploadFiles,
        activeCard,
        valuesGet,
      }}
    >
      {children}
    </ContratosContext.Provider>
  );
};

export const useContextContrato = (): IContextContrato =>
  useContext(ContratosContext);
