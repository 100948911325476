import { Children, useEffect, useState } from "react";
import { useContextComissoes } from "src/Contexts/comissoes/ComissoesContext";
import { useConfigPageContext } from "src/Contexts/configPagesContext/configPagesContext";
import { useResetInputs } from "src/Contexts/hooks/useResetInputs";
import { ModalDeleteCore, TableCore, TdCore } from "src/Pages/components";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  ModeEditIcon,
  AddHomeWorkIcon,
  DeleteForeverIcon,
} from "src/Pages/components/Icons/Icons";
import { UseDelete } from "src/Pages/components/ModalDeleteCore/Hooks/UseDelete";
import { TableRowCore } from "src/Pages/components/table/@Core/TableRowCore/TableRowCore";
import { ActionPopoverTable } from "src/Pages/components/table/ActionPopover/ActionPopover";
import { FormatDateBR } from "src/shared/Utils/FormatDateBR";

import { UseGetByIdCommissions } from "./Hooks/UseGetByIdCommissions";
import { useHttpTableCommissions } from "./Hooks/useHttpTableCommissions";
import { THeadCommissions } from "./THead";
import { ModalCommissions } from "../Form";
import { ButtonCore } from "src/Pages/components/ButtonCore/ButtonCore";
import { ModalGridCommissions } from "../ModalGridCommissions";

export const TableCommissions = () => {
  const { handleGetById } = UseGetByIdCommissions();
  const { listCommissions } = useContextComissoes();
  const { handleGet } = useHttpTableCommissions();
  const { resetInputs } = useResetInputs();

  const { setNameSearch, itensPerPage, currentPage, nameSearch, attTable } =
    useConfigPageContext();

  const [openModalCommissions, setOpenModalCommissions] = useState(false);
  const [openModalGridCommission, setOpenModalGridCommission] = useState(false);
  const [idCommission, setIdCommission] = useState("");

  const handleGetIdToEdit = (id: string) => {
    resetInputs();
    setIdCommission(id);
    setOpenModalCommissions(true);
    handleGetById(id);
  };
  const handleViewGridCommission = (id: string) => {
    setOpenModalGridCommission(true);
    handleGetById(id);
  };

  useEffect(() => {
    handleGet({ currentPage, itensPerPage, nameSearch });
  }, [attTable, itensPerPage, currentPage, nameSearch]);

  const {
    handleDelete,
    open: openModalDelete,
    loading: loadingDelete,
    setId: setIdModalDelete,
    setOpen: setOpenModalDelete,
  } = UseDelete({ url: "/commissions" });

  return (
    <TableCore
      THead={<THeadCommissions />}
      qtdList={listCommissions.length}
      setNameSearch={setNameSearch}
      Modals={
        <>
          <ModalGridCommissions
            openModal={openModalGridCommission}
            setOpenModal={setOpenModalGridCommission}
          />
          <ModalCommissions
            openModal={openModalCommissions}
            setOpenModal={setOpenModalCommissions}
            isModal
            isCommissionInternal
            idCommission={idCommission}
            setIdCommission={setIdCommission}
          />

          <ModalDeleteCore
            loading={loadingDelete}
            modalOpen={openModalDelete}
            no="Não, Cancelar"
            titlePrimary="Você deseja excluir da lista ?"
            yes="Sim, Deletar !"
            onClickFalse={() => setOpenModalDelete(false)}
            onClickTrue={() => handleDelete()}
          />
        </>
      }
      cardAdd={{
        title: "Comissões",
        icon: <AddHomeWorkIcon />,
        buttons: [
          <ButtonCore
            startIcon={<AddIcon />}
            title="Criar comissão"
            onClick={() => setOpenModalCommissions(true)}
          />,
        ],
      }}
    >
      {Children.toArray(
        listCommissions.map((item) => {
          return (
            <TableRowCore>
              <TdCore
                values={
                  <ActionPopoverTable
                    optionsList={[
                      {
                        icon: <RemoveRedEyeOutlinedIcon fontSize="small" />,
                        title: "Ver grade",
                        background: "warning",
                        onClick: () => {
                          handleViewGridCommission(item.code);
                        },
                      },
                      // {
                      //   title: item.isActive
                      //     ? "Desativar grade"
                      //     : "Ativar grade",
                      //   background: "warning",
                      //   isDisabled: true,

                      //   onClick: () => {
                      //     setId(item.code);
                      //     setOpenModalChangeIsActive(true);
                      //     setIsActiveCommissions(!item.isActive);
                      //   },
                      //   icon: item.isActive ? (
                      //     <FolderOffIcon fontSize="small" />
                      //   ) : (
                      //     <FolderCopyIcon fontSize="small" />
                      //   ),
                      // },
                      {
                        icon: <ModeEditIcon fontSize="small" />,
                        title: "Editar",
                        background: "warning",
                        onClick: () => {
                          handleGetIdToEdit(item.code);
                        },
                      },
                      {
                        icon: <DeleteForeverIcon fontSize="small" />,
                        title: "Excluir",
                        background: "error",
                        onClick: () => {
                          setIdModalDelete(item.code);
                          setOpenModalDelete(true);
                        },
                      },
                    ]}
                  />
                }
              />
              <TdCore values={item.operator.name} />
              <TdCore values={item.modality.name} />

              <TdCore
                values={
                  item.administrator?.name ? `${item.administrator?.name}` : ""
                }
              />

              <TdCore
                values={item.company?.name ? `${item.company?.name}` : ""}
              />

              <TdCore
                sxStyle={{
                  borderBottom: (theme) =>
                    `1px solid ${theme.palette.primary.main}`,
                }}
                textAlign="center"
                values={FormatDateBR(item.effectiveDate)}
              />

              <TdCore
                textAlign="center"
                values={
                  item.createdAt ? (
                    <CheckIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )
                }
              />
            </TableRowCore>
          );
        })
      )}
    </TableCore>
  );
};
